import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";

import { timeSlotStyles } from "../Session/components/AddTimeFrameModal/react-select-styles";
import { WarningIcon, WhiteCalendarPlusIcon } from "../../assets/icons/session";
import ConfirmAuditionModal from "./ConfirmAuditionModal/ConfirmAuditionModal";
import moment from "moment-timezone";
import useTalentSchedule from "./hooks/use-talent-schedule";
import { getOneSession, getStudioByUri, getUser, getUserById } from "../../services";
import AuthPage from "../Auth/Page";
import { USER_TYPES } from "../../constants";
import { FaExclamationTriangle, FaSpinner } from "react-icons/fa";
import TalentPage from "../TalentPage";
import "./style.scss";
import { TitleContext } from "../../Context";
import useProfile from "../TalentPage/hooks/use-profile";

const Schedule = ({ match, history }) => {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null)
  const [studio, setStudio] = useState(null)
  const [session, setSession] = useState(null)

  const pUrl = window.localStorage.getItem('prev_url')
  const studio_uri = match.params.uri
  const session_id = match.params.session_id

  const {
    isSessionExpired,
    sessionName,
    roleOptions,
    selectedTimeFrame,
    selectedTimeFrameDates,
    formattedSelectedTimeFrameDates,
    selectTimeSlot,
    auditionData,
    createdBy,
    userAppointment,
    selectRole,
    onSelectTimeSlot,
    clearAuditionData,
    currentDate
  } = useTalentSchedule({ match });

  const [loading, setLoading] = useState(false)
  const context = useContext(TitleContext);

  const { timezoneAbbr } = useProfile()

  useEffect(() => {
    const process = async () => {
      const studio = await getStudioByUri(studio_uri)
      const session = await getOneSession(session_id)
      if (studio && studio._id) { 
        context.setTitle(studio?.name);
        setStudio(studio)
      }
      if (session && session._id) { setSession(session) }
    }
    process()
  }, [studio_uri, session_id])

  useEffect(() => {
    const u = getUser()
    if(u){
      setLoading(true)
      getUserById(u.id).then(data => {
        setUser(data)
        setLoading(false)
      })
    }
    
  }, [])

  useEffect(()=> {
    return () => {
      context.setTitle('');
    };
  },[])

  useEffect(() => {
    if(user && user.first_name && pUrl){
      window.localStorage.removeItem('prev_url')
    }
  }, [user, pUrl])

  const logout = () => {
    window.localStorage.removeItem('token')
    window.location.reload(true)
  }

  if(loading){
    return (
      <div className='message-alert'>
        <FaSpinner size='32px' className='spinning' />
        <h4>Loading...</h4>
      </div>
    )
  }

  if (!user && !loading) {
    return (
      <AuthPage
        history={history}
        talentOnly={true}
        projectInfo={{studio:studio?.name, session:session?.name}}
      />
    )
  }
  if (user && user.user_type !== USER_TYPES.TALENT) {
    return (
      <div className='message-alert'>
        <FaExclamationTriangle size={32} />
        <h5>
          {user.email} is associated with a non-talent account type. Please create a talent profile to continue.
        </h5>
        <button href="#" onClick={() => {
          logout()
        }}>Logout</button>
      </div>
    )
  }
  if(user && !user.first_name){
    return(
      <TalentPage duringOnboard={true}/>
    )
  }

  return (
    <div className="schedule-page">
      <h1 className="h-32-400">{sessionName}</h1>
      {isSessionExpired &&
        <div className="already-booked-container">
          <div className="h-16-600">
            <WarningIcon /> 
            <span className="ml-2">This session date has passed</span>
          </div>
        </div>
      }
      {userAppointment &&
          <div className="already-booked-container">
            <div className="h-16-600">
              <WarningIcon /> 
              <span className="ml-2">You have booked the following appointment for this session:</span>
            </div>
            <div className="data-section">
              <div className="data-item">
                <span className="h-12-600">Role:</span>
                <span className="h-14-400">{userAppointment.role}</span>
              </div>
              <div className="data-item">
                <span className="h-12-600">Date:</span>
                <span className="h-14-400">{moment(userAppointment.start_time).format("YYYY-MM-DD")}</span>
              </div>
              <div className="data-item">
                <span className="h-12-600">Time:</span>
                <span className="h-14-400">{moment(userAppointment.start_time).format("h:mm A")} ({timezoneAbbr})</span>
              </div>
            </div>
            <div className="h-16-400">
              <span>If you need to cancel your appointment, please email &nbsp;</span>
              <a href={`mailto:${createdBy?.email}`} className="email">{createdBy?.email}</a>
            </div>
          </div>
      }
      
      <div className="schedule-content-container">
        <h4 className="h-14-400">
          Please fill in the details below to confirm your audition
        </h4>
        <div className="input-container">
          <span className="h-12-600">Your Role</span>
          <Select
            isSearchable={false}
            onChange={(e) => selectRole(e?.value)}
            options={roleOptions}
            styles={timeSlotStyles}
          />
        </div>
        <div className="input-container">
          {selectedTimeFrameDates.map((selectedTimeFrameDate, i) => (
            <div key={selectedTimeFrameDate}>
              <div className="h-14-600">
                {formattedSelectedTimeFrameDates[i]}
              </div>
              <div className="h-12-400 secondary-color mb-3 mt-2">Select a time</div>
              <div className="time-slot-container">
                <div className="time-slot">
                  {selectedTimeFrame?.[selectedTimeFrameDate]?.map((timeFrame) => (
                    <button
                      className={
                        "time-slot-item h-14-400" +
                        (selectTimeSlot?.slot?.start === timeFrame?.start ? " selected" : "")
                      }
                      onClick={() => onSelectTimeSlot({slot:timeFrame, date:selectedTimeFrameDate})}
                    >
                      {timeFrame?.start} ({timezoneAbbr})
                    </button>
                  ))}
                </div>
              </div>
              <div>
                <span className="h-12-400 secondary-color">Can’t find a time that works? &nbsp;</span>
                <span className="h-12-400 text-danger">Contact&nbsp;</span>
                <a
                  href={`mailto:${createdBy?.email}`}
                  className="h-12-400 text-danger underline"
                  _target="blank"
                >
                  {createdBy?.email}
                </a>
                <span className="h-12-400 text-danger">&nbsp;who is scheduling this session</span>
              </div>
            </div>
          ))}

          <div className="confirm-button-container">
            <button
              className="btn btn-primary h-14-700 br-8"
              onClick={() => setShowModal(true)}
              disabled={!selectTimeSlot?.slot?.start}
            >
              <WhiteCalendarPlusIcon /> Confirm
            </button>
          </div>
        </div>
        { (isSessionExpired || userAppointment) && <div className="bg-overlay"></div>}
      </div>
      <ConfirmAuditionModal
        showModal={showModal}
        setShowModal={setShowModal}
        auditionData={auditionData}
        clearAuditionData={clearAuditionData}
        currentDate={currentDate}
      />
    </div>
  );
};

export default Schedule;
