import React, { useEffect, useRef, useState } from "react";
import { static_root } from "../../../services";
import "./style.scss";
import { CloseIconBlack } from "../../../assets/icons/session";
import DatePicker from "react-datepicker";

const AboutMe = ({
  email,
  photos,
  setPhotos,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  uploadPhoto,
  updateUser,
  uploading,
  phoneNumber,
  setPhoneNumber,
  saving,
  formError,
  dateOfBirth,
  duringOnboard=false,
  setDateOfBirth
}) => {
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e) => {
    dragItem.current = e.target.id
  }

  const dragEnter = (e) => {
    dragOverItem.current = e.currentTarget.id
  }

  const drop = () => {
    const listItems = [...photos]
    const dragItemContent = listItems[dragItem.current]
    const dragOverItemContent = listItems[dragOverItem.current]

    listItems.splice(dragItem.current, 1, dragOverItemContent )
    listItems.splice(dragOverItem.current, 1 , dragItemContent)

    dragItem.current = null
    dragOverItem.current = null
    setPhotos(listItems) 

  }


  return (
    <div className="AboutMe white-bg">
      <div className="top">
        <h3 className="h-16-600">Info</h3>
        <div className="input-row">
          <div className="input-item">
            <label htmlFor="firstname">First Name *</label>
            <input
              value={firstName}
              onChange={(ev) => setFirstName(ev.target.value)}
              type="text"
              name="firstname"
              id="firstname"
              className="input"
              required
            />
          </div>
          <div className="input-item">
            <label htmlFor="lastname">Last Name *</label>
            <input
              value={lastName}
              onChange={(ev) => setLastName(ev.target.value)}
              type="text"
              name="lastname"
              id="lastname"
              className="input"
              required
            />
          </div>
        </div>
        <div className="input-row">
          <div className="input-item dateInputWrap">
            <label>Phone * (for SMS audition instructions)</label>
            <input
              value={phoneNumber}
              onChange={(ev) => setPhoneNumber(ev.target.value)}
              type="text"
              name="phone"
              id="phone"
              className="input"
              required
            />
          </div>
        </div>
        <div className="input-row">
          <div className="input-item dateInputWrap">
            <label>Date of Birth (if under 18)</label>
            <DatePicker
              selected={dateOfBirth}
              onChange={(date) => setDateOfBirth(date)}
              className="input"
              isClearable
            />
          </div>
        </div>
        <div className="form-group mb-0">
          <label className="mr-2">Email: </label>
          <label className="font-weight-bold">{email}</label>
        </div>
      </div>
      <div className="horizontal-divider" />
      <div className="bottom">
        <div className="">
          <h3 className="h-16-600">Headshots *</h3>
          <label className="h-12-400 secondary-color">
            600px x 600px JPG/PNG images recommended (at most 4 photos)
          </label>
        </div>
        {photos.length > 0 && (
          <div className="photos-wrapper">
            {photos.map((photo, index) => {
              return (
                <div key={index} className="photo-item-wrap" draggable onDragStart={e => dragStart(e)} onDragEnter={ e => dragEnter(e)} onDragEnd={drop} id={index}>
                  <img
                    src={static_root + photo}
                    className="photo-item"
                    alt="profile"
                    id={index}
                  />
                  <span
                    className="remove-photo-item"
                    onClick={() => {
                      setPhotos(photos.filter((p) => p !== photo));
                    }}
                  >
                    <CloseIconBlack />
                  </span>
                </div>
              );
            })}
          </div>
        )}
        <input
          key={uploading}
          type="file"
          id="photo"
          accept="image/*"
          multiple
          onChange={(ev) => uploadPhoto(ev.target.files)}
          hidden
        />
        {photos.length < 4 && (
          <button
            disabled={uploading}
            className="h-14-600 upload-button"
            onClick={() => {
              document.querySelector("#photo").click();
            }}
          >
            {uploading ? "Uploading..." : "Upload Headshots"}
          </button>
        )}
        <div className="d-flex align-items-center">
          {formError && <div className="error-indicator">{formError}</div>}
          <button
            type="submit"
            className="primary-solid-button h-14-700 ml-auto"
            onClick={updateUser}
          >
            {saving ? 'Saving' :(duringOnboard ? 'Save & Continue': 'Save')}
          </button>
        </div>

      </div>
    </div>
  );
};

export default AboutMe;
