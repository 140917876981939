import React from "react";
import { Link } from 'react-router-dom'

import { static_root } from "../../../services";

import "./style.scss";

const InfoCard = ({
  firstName,
  lastName,
  phoneNumber,
  agent,
  photos,
  setShowEditInfo,
  agentPhone
}) => {
  return (
    <div className="info-card">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="h-16-600 ls-2">Your Information</h2>
        <div className="d-flex align-items-center">
          <Link 
            to="/"
            className="primary-solid-button h-12-700 text-white mr-2"
          >
            View full profile
          </Link>
          <button
            className="primary-outline-button h-12-600"
            onClick={() => setShowEditInfo(true)}
          >
            Edit
          </button>

        </div>
      </div>
      <div className="info-container">
        <div className="info-left">
          <div className="d-flex flex-column info-item">
            <div className="h-12-600">First Name</div>
            <div className="h-14-400 mt-1">{firstName}</div>
          </div>
          <div className="d-flex flex-column info-item">
            <div className="h-12-600">Last Name</div>
            <div className="h-14-400 mt-1">{lastName}</div>
          </div>

          <div className="d-flex flex-column info-item">
            <div ><span className="h-12-600">Phone </span><span className="h-12-500">(for SMS audition instructions)</span></div>
            <div className="h-14-400 mt-1">{phoneNumber}</div>
          </div>
          <div className="d-flex flex-column info-item">
            <div className="h-12-600">Agent</div>
            <div className="h-14-400 mt-1">{agent}</div>
          </div>
          <div className="d-flex flex-column info-item">
            <div className="h-12-600">Agent Phone</div>
            <div className="h-14-400 mt-1">{agentPhone}</div>
          </div>
        </div>
        <div className="">
          <div className="h-12-600">Headshots</div>
          <div className="checkin-image-container">
            {photos?.map((headshot, index) => {
              return (
                <img
                  key={index}
                  className="checkin-headshot"
                  src={`${static_root}${headshot}`}
                  alt="headshot"
                  style={{ objectFit: "cover" }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
