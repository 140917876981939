import React, {
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  useDispatch
} from 'react-redux'
import {
  Modal,
  Form,
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap'

import {
  FaPen
} from 'react-icons/fa'

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import clsx from 'classnames'
import Select from "react-select";
import { timeSlotStyles } from "../Session/components/AddTimeFrameModal/react-select-styles";

import {
  PROJECT_TYPES,
  USER_TYPES,
  USER_TYPE
} from '../../constants'

import {
  searchUsers,
  createStudio,
  updateStudio,
  getManyStudios,
  getUser,
  getUserById,
  getLimeLiteProjects
} from '../../services'

import {
  set as setStudiosInStore,
  update as updateStudioInStore,
} from '../../store/studios'

export default function ({
  studio,
  show,
  onHide,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedCastingDirector, setSelectedCastingDirector] = useState(studio ? studio.casting_directors : []);
  const [castingDirectors, setCastingDirectors] = useState([]);
  const [loadingSessionUsers, setLoadingSessionUsers] = useState(false);
  const [errors, setErrors] = useState({});
  const [showAuditionPurchaseMsg, setShowAuditionPurchaseMsg] = useState(studio && studio.type === PROJECT_TYPES.CREATOR);
  const dispatch = useDispatch()

  const [limeliteProjects, setLimeliteProjects] = useState([])
  const [formatedLLProjects, setFormatedLLProjects] = useState([])
  const [selectedLLProject, setSelectedLLProject] = useState(null)
  const [opportunityList, setOpportunityList] = useState([]);
  const [opportunityIdList, setOpportunityIdList] = useState([]);
  const [user, setUser] = useState({})

  useEffect(() => {
    const user = getUser()
    getUserById(user.id).then(data => setUser(data))
    const email = user.email
    async function fetchData() {
      await getLimeLiteProjects({ email }).then(res => {
        setLimeliteProjects(res.data)
      }).catch(err => {
        console.log('error in limelite project', err)
      })
    }
    fetchData();
  }, [])

  useEffect(() => {
    const _formatedLLProjects = []
    if(limeliteProjects?.length > 0 ){
      limeliteProjects.forEach(project => {
        _formatedLLProjects.push({
          label: project.name,
          value: project.id
        })
      })
      setFormatedLLProjects(_formatedLLProjects)
    } 

  }, [limeliteProjects])

  useEffect(() => {
    if(studio && studio._id && studio?.limelite_project_id){
      const _selectedProject = limeliteProjects.find(project => project.id === studio.limelite_project_id);
      setSelectedLLProject(_selectedProject)
      setOpportunityList(studio.project_roles)
      setOpportunityIdList(studio.limelite_project_role_ids)
    }
  }, [studio, limeliteProjects])

  const initialData = !!studio ? studio : {};

  useEffect(() => {
    setSelectedCastingDirector(studio && studio.casting_directors || [])
  }, [studio])

  const searchCastingDirectors = async (email) => {
    setLoadingSessionUsers(true);
    const users = await searchUsers(email, USER_TYPES.CASTING_DIRECTOR);
    setCastingDirectors(users);
    setLoadingSessionUsers(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if(selectedCastingDirector.length > 0) {
      formData.append('casting_directors', selectedCastingDirector.map(it=>it._id))
    }
    if(selectedLLProject){
      formData.append('name', selectedLLProject.name);
      formData.append('limelite_project_id', selectedLLProject.id)
      formData.append('project_roles', JSON.stringify(opportunityList))
      formData.append('limelite_project_role_ids', JSON.stringify(opportunityIdList))
      formData.append('allow_talent_create_role', false)
    }
    if (!!studio._id) {
      updateStudio(formData, studio._id).then(res => {
        dispatch(updateStudioInStore(res))
        onHide(res);
      }).catch(err => {
        console.log(err);
        onHide()
      });
    } else {
      formData.append('created_by', user._id)

      createStudio(formData).then(res => {
        getManyStudios(0, 10).then(res=>{
          dispatch(setStudiosInStore(res))
        })
        onHide(res);
      }).catch(err => {
        console.log(err);
        onHide()
      });
    }
  };

  const onCloseModal = () => {
    onHide();
    setShowDetails(false);
  }

  const onSelectLimeLightProject = (e) => {
    setOpportunityList([])
    setOpportunityIdList([])

    if(!e){
      setSelectedLLProject(null)
      return
    } 
    const _selectedProject = limeliteProjects.find(project => project.id === e.value);
    setSelectedLLProject(_selectedProject)
  }
  
  const addOpporunities = (ev) => {
    let oppList = [...opportunityList];
    let idList = [...opportunityIdList];
    if (ev.target.checked) {
      oppList.push(ev.target.value);
      idList.push(ev.target.id);
    } else {
      oppList = oppList.filter((it) => it !== ev.target.value);
      idList = idList.filter((it) => it !== ev.target.id);
    }
    setOpportunityList(oppList);
    setOpportunityIdList(idList);
  }

  return (
    <Modal
      dialogClassName={clsx({ "fullscreen-modal": showDetails })}
      show={show}
      onHide={onCloseModal}
      className="limelite-modal"
      centered
    >
      <Form
        className="d-flex flex-column h-100"        
        onSubmit={onSubmit}
      >
        <Modal.Header closeButton className="align-items-center">
          <h4 className="mb-0 mr-3">{(studio && studio._id) ? `Update ${studio.name}` : 'Create Project'}</h4>
          {!studio && showDetails && (
            <p className="h6 font-weight-normal mt-1">
              Please make sure all credentials are preperly configured for your account.
            </p>
          )}
          {showDetails && studio && studio.casting_directors && studio.casting_directors.length > 0 && (
            <label className="mb-0">
              <span className="mr-1">Director: </span>
              {studio.casting_directors.map(director => director.email).join(',')}
            </label>
          )}
        </Modal.Header>
        <Modal.Body className="overflow-auto">
        
          {!showDetails && (
            <div>
              {user?.is_limelite_user && !(studio?._id && !studio?.limelite_project_id) && (
                <div>
                  <div>
                    <h5 className="h-12-600 ls-2 mb-2">To begin, select a Limelite project</h5>
                    <Select
                      isClearable
                      isSearchable={false}
                      onChange={(e) => onSelectLimeLightProject(e)}
                      options={formatedLLProjects}
                      value={selectedLLProject? {label: selectedLLProject?.name, value: selectedLLProject?.id}: null}
                      styles={{...timeSlotStyles, menuList: (base) => ({...base, height: '200px' })}}
                      isDisabled={!!studio?._id}
                    />
                  </div>
                  {selectedLLProject && (
                    <div className="mt-4">
                      <h5 className="h-12-600 ls-2 mb-2">Opportunities (Roles) *</h5>
                      <div className="opportunities-list">
                        {selectedLLProject.projectRoles.map((role) => {
                        return (
                          <div className="d-flex align-item-center opp-item" key={role.id}>
                            <input
                              id={role.id}
                              type="checkbox"
                              value={role.name}
                              onChange={(ev) => {
                                addOpporunities(ev)
                              }}
                              checked={opportunityList.includes(role.name)}
                            />
                            <label className="ml-2 mb-0 h-14-400 ls-2" htmlFor={role.id}>
                              {role.name}
                            </label>
                          </div>
                        )})}
                      </div>
                    </div>   
                  )} 
                </div>
              )}
              
              {(!studio?.limelite_project_id && !selectedLLProject) && (
                <>
                  <div className={!user?.is_limelite_user ? 'd-none' : ''}>
                    <div className={!!studio?._id ? 'd-none' : 'or-divider'}>
                      <span className='h-14-600 text-muted'>Or</span>
                      <div className='divider-line'/>
                    </div>
                    <span className='h-14-600 text-muted'>{!!studio?._id ? 'Update' : 'Create a'} Hey Joe Project (recordings won't be synced to Limelite)</span>
                  </div>
                  <div>
                    <div className='d-flex flex-column my-3'>
                      <div className='d-flex flex-column'>
                        <h5 className="h-12-600 ls-2 mb-2">Project Name</h5>
                        <div className='flex-fill'>
                          <Form.Control
                            required
                            type="text"
                            name="name"
                            defaultValue={initialData.name || ''} />
                        </div>
                      </div>
                      <div
                        className="d-flex cursor-pointer align-items-center mt-1"
                        onClick={() => { setShowDetails(true); } }
                      >
                        <span className='mr-2'>Advanced Details</span>
                        <FaPen />
                      </div>
                    </div>
                  </div>
                </>

              )}
              {USER_TYPE.IS_SUPER_ADMIN() && (
                <Form.Group className="my-3">
                  <Form.Label>Assign casting directors</Form.Label>
                  <AsyncTypeahead
                    id="casting-director-email"
                    selected={selectedCastingDirector}
                    onChange={value => {
                      setSelectedCastingDirector(value);
                    } }
                    isLoading={loadingSessionUsers}
                    labelKey="email"
                    minLength={2}
                    onSearch={searchCastingDirectors}
                    options={castingDirectors}
                    placeholder="Search for a Session user..."
                    name="casting_directors" />
                </Form.Group>
              )}
            </div>
          )}
          <Container
            fluid
            className={clsx({ "d-none": !showDetails })}
          >
            <Row>
              {showDetails && (
                <Col>
                  <Form.Group>
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control
                      size="sm"
                      required
                      type="text"
                      name="name"
                      defaultValue={initialData.name || ''} />
                  </Form.Group>
                </Col>
              )}
              <Col>
                <Form.Group>
                  <Form.Label>Uri</Form.Label>
                  <Form.Control
                    required
                    size="sm"
                    type="text"
                    name="uri"
                    defaultValue={initialData.uri || ''} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Meeting ID</Form.Label>
                  <Form.Control
                    required
                    size="sm"
                    id="jitsi_meeting_id"
                    type="text"
                    name="jitsi_meeting_id"
                    defaultValue={initialData.jitsi_meeting_id || ''} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Test Meeting ID</Form.Label>
                  <Form.Control
                    required
                    size="sm"
                    type="text"
                    name="test_meeting_id"
                    defaultValue={initialData.test_meeting_id || ''} />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Back Office Meeting ID</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="back_office_meeting_id"
                defaultValue={initialData.back_office_meeting_id || ''} />
            </Form.Group>
            {USER_TYPE.IS_SUPER_ADMIN() && showDetails && (
              <Form.Group className="my-3">
                <Form.Label>Assign casting directors</Form.Label>
                <AsyncTypeahead
                  id="casting-director-email"
                  selected={selectedCastingDirector}
                  onChange={value => {
                    setSelectedCastingDirector(value);
                  } }
                  isLoading={loadingSessionUsers}
                  labelKey="email"
                  minLength={2}
                  onSearch={searchCastingDirectors}
                  options={castingDirectors}
                  placeholder="Search for a Session user..."
                  name="casting_directors" />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Thankyou_message</Form.Label>
              <Form.Control
                required
                size="sm"
                type="text"
                name="thankyou_message"
                defaultValue={initialData.thankyou_message || `Thank you for checking in to PROJECT_NAME. To join virtual lobby click TALENT_STATUS_LINK. You may use this link on any device`} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Position_messages 1</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="position_messages"
                defaultValue={(initialData.position_messages && initialData.position_messages[0]) || ''} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Position_messages 2</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="position_messages"
                defaultValue={(initialData.position_messages && initialData.position_messages[1]) || ''} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Position_messages 3</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="position_messages"
                defaultValue={(initialData.position_messages && initialData.position_messages[2]) || ''} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Position_messages 4</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="position_messages"
                defaultValue={(initialData.position_messages && initialData.position_messages[3]) || ``} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Good bye message</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                name="good_bye_message"
                defaultValue={initialData.good_bye_message || ''} />
            </Form.Group>
            <Form.Group>
              <Form.Check
                label="Creator Project"
                name="project_type"
                value={PROJECT_TYPES.CREATOR}
                defaultChecked={initialData.type === PROJECT_TYPES.CREATOR}
                onChange={(ev) => {
                  setShowAuditionPurchaseMsg(ev.target.checked);
                } } />
            </Form.Group>
            {showAuditionPurchaseMsg && (
              <Form.Group>
                <Form.Label>Audition Purchase Message</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="audition_purchase_message"
                  defaultValue={initialData.audition_purchase_message || `Thank you for auditioning for PROJECT_NAME. Get a link to your audition footage to save and share : LINK_TO_PURCHASE_INVOICE`} />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Logo</Form.Label>
              <Form.Control
                type="file"
                name="logo"
                accept=".png, .jpg, .jpeg" />
            </Form.Group>
            <Form.Group>
              {errors.uri &&
                <p className="text-danger mb-1">Studio uri <strong>{errors.uri}</strong> already used!</p>}
              {errors.meeting_id &&
                <p className="text-danger mb-1">Meeting id <strong>{errors.meeting_id}</strong> already used!</p>}
            </Form.Group>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className='primary-solid-button h-14-700'
          >
            {(studio && studio._id) ? 'Update' : 'Create'}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
