/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { Modal, Dropdown } from 'react-bootstrap'
import Switch from "react-switch"
import { FaImage, FaArchive, FaTeethOpen, FaCopy, FaDownload, FaTrash, FaPlus, FaEye, FaSpinner, FaList, FaEyeSlash } from 'react-icons/fa'
import {
  static_root,
  getStudioByUri,
  copyGroupFromSession,
  getPagesByStudio,
  getOneSession,
  getSessionVideos,
  createZipAndSendMail,
  getArchivedSessionVideos,
  deleteVideo,
  updateVideo,
  updateManyVideo,
  uploadNewVideo,
  updateGroup,
  getUser,
  createPage,
  fetchCheckInList,
  twrGetTWRByDomain,
  twrGetStudioByTWRUri,
  twrFetchCheckInList,
  twrGetHeyjoeSessionRecords,
  getNotification,
  updateGroupViewed,
  directUpload,
  addVideoEntry,
  uploadFile,
  updateManyRecords,
  updateLimeLiteFolder
} from '../../services'
import Footer from '../../components/Footer'
import './style.scss'
import ReactPlayer from 'react-player'
import { saveAs } from 'file-saver'
import { VIDEO_REVIEW_PERMISSIONS, USER_TYPE } from '../../constants'
import SyncComponent from '../../components/sync'
import { joinRoom, leaveRoom } from '../../ws'
import PersonCardV from '../PostingPage/FbkC'
import { listPageFeedbacks } from '../../services/feedback'
import { dataURLtoFile, getVideoScreenshot } from '../../utils'
import { ArchiveIcon, EditIconSmall } from '../../assets/icons/session'
import CreatePostingPage from '../Studio/CreatePostingPage'

const itemWidth = 250
const thumbWidth = 150

const TABS = {
  VIDEOS: 'Videos',
  ARCHIVED: 'Archived',
  VIEWED: 'Viewed'
}
let noticeField = ''
let noticeUpdatedAtField = ''
let noticeTitle = ''

const user = getUser()

class VideoPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      studio: null,
      session: null,
      countPerRow: 5,
      activeRidx: -1,
      activeGidx: -1,
      activeItem: null,
      videos: [],
      groups: [],
      records: {},
      loading: false,
      videoDates: [],
      selectedForUploads: [],
      groupRecords: [],
      twrCandidates: [],
      twrGroupRecords: [],
      twrStudio: '',
      archivedVideos: [],
      selectedPage: null,
      postingPages: [],
      tab: TABS.VIDEOS,
      selectedGroup: {},
      newPostingPage: {},
      selectedTalentRecords: [],
      loadingTalentRecords: false,
      sessionTalentOptions: [],
      showNotification: '',
      notification: {},
      pageFeedbacks: [],
      filter: 'all',
      roleFilter: 'all',
      showAvatar: false,
      hideViewed: false,
      hideCopied: false,
      limeliteChecked: false,
      showCreatePostingPageModal: false,
    }
  }

  setCount = () => {
    this.setState({
      countPerRow: Math.max(1, parseInt((document.documentElement.clientWidth - 96) / (itemWidth + 32)))
    })
  }

  setFilter = (v) => { this.setState({
    filter: v,
    activeRidx: -1,
    activeGidx: -1,
    activeItem: null
  }) }
  setRoleFilter = (v) => { this.setState({
    roleFilter: v,
    activeRidx: -1,
    activeGidx: -1,
    activeItem: null
  }) }
  setHideViewed = (v) => { this.setState({
    hideViewed: v,
    activeRidx: -1,
    activeGidx: -1,
    activeItem: null
  }) }
  setHideCopied = (v) => { this.setState({
    hideCopied: v,
    activeRidx: -1,
    activeGidx: -1,
    activeItem: null
  }) }

  fetchTWRStudio = async () => {
    if (!this.state.session.twr) { return }
    const { twr } = this.state.session
    const parsed = twr.match(/(\w+)\/(\w+)/)
    if (parsed) {
      const twrDomain = parsed[1]
      const twrStudioUri = parsed[2]
      const room = await twrGetTWRByDomain(twrDomain)
      const result = await twrGetStudioByTWRUri(room._id, twrStudioUri)
      this.setState({ twrStudio: result._id }, () => { this.loadVideos() })
    }
  }

  fetchTWRCandidates = async () => {
    const { twrStudio, session } = this.state
    if (!twrStudio) { return [] }
    let candidates = await twrFetchCheckInList(twrStudio)
    const heyjoeCandidates = await twrGetHeyjoeSessionRecords(session._id)
    candidates = candidates.map((c, idx) => {
      const hc = heyjoeCandidates.find(h => h.twr_id === c._id)
      return {
        ...c,
        ...hc,
        number: idx + 1,
        _id: c._id,
        twr_id: c._id,
      }
    })
    return candidates
  }

  loadFeedbacks = async () => {
    const res = await listPageFeedbacks({page_id: this.session_id})
    this.setState({
      pageFeedbacks: res.filter(f => !f.from_user)
    })
  }

  loadVideos = async () => {
    this.setState({
      loading: true
    })
    let loadFunc = null
    if ([TABS.VIDEOS, TABS.VIEWED].includes(this.state.tab)) loadFunc = getSessionVideos
    if (this.state.tab === TABS.ARCHIVED) loadFunc = getArchivedSessionVideos
    let videos = await loadFunc(this.session_id)
    let groups = [], gidx = {}, idx = 0, groupRecords = []
    videos.forEach(video => {
      let groupName = video.group ? video.group.records.map(r => `${r.first_name} ${r.last_name}`).join(',') : ''
      if (!video.group) {
        video.group = { records: [] }
      }
      if (video.group.name && !video.group.name.includes('reserved field')) {
        groupName = video.group.name
      }
      if (this.state.tab === TABS.VIEWED && !this.isGroupViewed(video.group._id)) {
        return
      }
      if (isNaN(gidx[video.group._id])) {
        gidx[video.group._id] = idx
        groups[gidx[video.group._id]] = {
          _id: video.group._id,
          name: groupName || 'Unknown',
          idx,
          url: video.url,
          thumbnail: video.group.thumbnail || video.thumbnail,
          note_to_casting_director: video.group.note_to_casting_director,
          videos: [],
          records: [],
          copied: video.group.copied
        }
        idx++
      }
      groups[gidx[video.group._id]].videos.push(video)
      video.group.records.forEach(r => {
        const addedIds = groups[gidx[video.group._id]].records.map(item => item._id)
        if (!addedIds.includes(r._id)) {
          groups[gidx[video.group._id]].records.push(r)
        }
      })
    })
    let twrCandidates = []
    if (this.state.session.twr) {
      twrCandidates = await this.fetchTWRCandidates()
    }
    this.setState({
      videos,
      groups,
      twrCandidates,
      groupRecords: groups[this.state.activeGidx]?.records || [],
      loading: false
    })
  }

  loadPostingPages = async () => {
    const pages = await getPagesByStudio(this.state.studio._id)
    this.setState({
      postingPages: pages,
      selectedPage: (pages[0] || {})._id
    })
  }

  downloadAllVideos = () => {
    const { selectedForUploads, session, studio } = this.state
    const email = window.prompt(
      `You are downloading ${selectedForUploads.length} videos.\nSpecify your email address to get download link`,
      window.localStorage.getItem('email')
    )
    if (!email) {
      return
    }
    createZipAndSendMail(selectedForUploads, `${studio.name}-${session.name}`, email)
      .then(() => {
        alert(`You will get an email with the download link once the archive is completed`)
        this.setState({
          selectedForUploads: []
        })
      })
  }

  downloadOneVideo = (video) => {
    saveAs(video.url, video.uri)
  }

  handleGroupItemClick = async (ridx, gidx) => {
    let twrGroupRecords = []
    if (this.state.session.twr) {
      if (this.state.groups[gidx]) {
        const group = this.state.groups[gidx].videos[0].group
        if (group) {
          twrGroupRecords = this.state.twrCandidates.filter(c => group.twr_records.includes(c.twr_id))
        }
      }
    }
    if (gidx === this.state.activeGidx) {
      this.setState({
        activeRidx: -1,
        activeGidx: -1,
        activeItem: null
      })
    }
    const group = this.state.groups[gidx].videos[0].group
    this.setState({
      activeRidx: ridx,
      activeGidx: gidx,
      activeItem: this.state.groups[gidx].videos[0],
      groupRecords: (group && group.records) || [],
      twrGroupRecords
    })
  }

  groupSelectedForDownload = (gidx) => {
    const { selectedForUploads } = this.state
    return !this.state.groups[gidx].videos.filter(v => !selectedForUploads.includes(v.uri)).length
  }

  toggleGroupSelectedForDownload = (gidx, checked) => {
    const { selectedForUploads } = this.state
    const newUploads = Object.assign([], selectedForUploads)
    this.state.groups[gidx].videos.forEach(v => {
      const vIdx = newUploads.findIndex(s => s === v.uri)
      if (checked && !newUploads.includes(v.uri)) { newUploads.push(v.uri) }
      if (!checked && newUploads.includes(v.uri)) { newUploads.splice(vIdx, 1) }
    })
    this.setState({
      selectedForUploads: newUploads
    })
  }

  toggleVideoSelectedForDownload = (uri, checked) => {
    const { selectedForUploads } = this.state
    const newUploads = Object.assign([], selectedForUploads)
    const vIdx = selectedForUploads.findIndex(s => s === uri)
    if (checked && !newUploads.includes(uri)) { newUploads.push(uri) }
    if (!checked && newUploads.includes(uri)) { newUploads.splice(vIdx, 1) }
    this.setState({
      selectedForUploads: newUploads
    })
  }

  changeTab = (tab) => {
    if (this.state.tab === tab) { return }
    this.setState({
      tab,
      activeRidx: -1,
      activeGidx: -1
    }, this.loadVideos)
  }

  handleArchiveVideo = async (video_id, archive) => {
    await updateVideo(video_id, { is_archived: archive })
    this.loadVideos()
  }

  handleVideoDelete = async (video_id) => {
    const result = window.confirm(`Are you sure?`)
    if (result) {
      await deleteVideo(video_id)
      this.loadVideos()
    }
  }

  handleGroupArchive = async (video_ids, archive) => {
    await updateManyVideo(video_ids, { is_archived: archive })
    this.loadVideos()
  }

  handleArchiveManyGroup = async () => {
    this.setState({ loading: true })
    const { videos, selectedForUploads } = this.state
    const videoIds = videos.filter(v => selectedForUploads.includes(v.uri)).map(v => v._id)
    await updateManyVideo(videoIds, { is_archived: true })
    this.setState({ selectedForUploads: [] })
    this.loadVideos()
  }

  uploadNewVideo = async (file) => {
    this.setState({ loading: true })
    const activeGroup = this.state.groups[this.state.activeGidx]
    // await uploadNewVideo(file, this.session_id, activeGroup._id)

    const { studio, session } = this.state
    const res = await directUpload(file, studio.jitsi_meeting_id)
    await addVideoEntry(res.Key, activeGroup._id, session._id)

    this.loadVideos()
  }

  mounted = async () => {
    this.setCount()
    this.session_id = this.props.match.params.session_id
    const studio_uri = this.props.match.params.uri
    const studio = await getStudioByUri(studio_uri)
    const session = await getOneSession(this.session_id)

    if (!studio) { return }
    document.title = `${studio.name} Video Review`;
    if (studio.logo) {
      document.querySelector('#header-logo').innerHTML = `<img src="${static_root + studio.logo}" class="header-logo" />`
    }

    let n = await getNotification()
    n = n || {}
    if (USER_TYPE.CASTING_DIRECTOR()) {
      noticeField = 'casting_director_notice'
    }
    if (USER_TYPE.SESSION_MANAGER()) {
      noticeField = 'session_manager_notice'
    }
    noticeTitle = noticeField && noticeField.split('_').map(n => n[0].toUpperCase() + n.slice(1)).join(' ')
    noticeUpdatedAtField = `${noticeField}_updated_at`
    let showNotification = ''
    if (window.localStorage.getItem(noticeUpdatedAtField) !== n[noticeUpdatedAtField]) {
      showNotification = noticeField
    }

    this.setState({
      studio,
      session,
      showNotification,
      notification: n
    }, async () => {
      await this.loadVideos()
      await this.loadPostingPages()
      // await this.fetchTWRStudio()
      await this.loadFeedbacks()
    })

    window.addEventListener('resize', this.setCount)

    joinRoom(`feedback-page-${session._id}`)
    document.body.addEventListener('ws-message', this.wsMessageHandler)

    if(studio.limelite_project_id && VIDEO_REVIEW_PERMISSIONS.CAN_COPY_TO_LIMELITE()){
      this.setState({
        limeliteChecked: true
      })
    }
  }

  wsMessageHandler = (event) => {
    try {
      const ev = event.detail
      switch (ev.type) {
        case 'feedback-page':
          this.loadFeedbacks()
      }
    } catch (err) {
      console.log('socket msg handle err: ', err);
    }
  }

  componentDidMount() {
    this.mounted()
  }

  componentDidUpdate() {
    if (this.state.activeItem && this.played !== this.state.activeItem.uri) {
      this.played = this.state.activeItem.uri
      setTimeout(() => {
        const video = document.querySelector('#active-player video')
        if (video) {
          video.play()
          video.addEventListener('ended', () => {
            const activeGroup = this.state.groups[this.state.activeGidx]
            const currentTabVideos = activeGroup.videos
              .filter(v => (!!v.is_archived === (TABS.ARCHIVED === this.state.tab)))
            const nextVideoIdx = currentTabVideos.findIndex(v => v.uri === this.state.activeItem.uri) + 1
            if (nextVideoIdx < currentTabVideos.length) {
              setTimeout(() => {
                this.setState({
                  activeItem: currentTabVideos[nextVideoIdx]
                })
              }, 1200)
            }
          })
        }
      }, 1000)
    }
  }

  componentWillUnmount() {
    leaveRoom(`feedback-page-${this.state.session?._id}`)
    document.body.removeEventListener('ws-message', this.wsMessageHandler)
  }

  getMimeType = (fileName) => {

    const extension =   fileName?.split('.').pop();

    let mimeType = '';
    switch(extension){
      case 'png':
        mimeType = 'image/png';
        break;
      case 'apng':
        mimeType = 'image/apng';
        break;
      case 'jpg':
      case 'jpeg':
        mimeType = "image/jpeg";
        break;
      case 'svg':
        mimeType = "image/svg";
        break;
      case 'webp':
        mimeType = 'image/webp';
        break;
      case 'gif':
        mimeType = "image/gif";
        break;
      case 'heif':
      case 'heic':
        mimeType = "image/heif";
        break;
      case 'avif':
        mimeType = "image/avif";
        break;
      default:
        mimeType = '';
        break;
    }
    return mimeType;
  }

  handleGroupsCopy = async () => {
    this.setState({
      loading: true,
      showPageCopyModal: false
    })

    let sendLink = false;

    if(USER_TYPE.IS_SUPER_ADMIN()){
      sendLink = document.querySelector('#send-audition-link').checked
    }
    
    let selectedPage = '' 
    if(!this.state.newPostingPage._id){
      const res = await createPage({
        name: this.state.newPostingPage.name,
        studio: this.state.studio._id
      })
      selectedPage = res._id
      await this.loadPostingPages(this.state.studio._id)
    } else {
      selectedPage = this.state.newPostingPage._id
    }

    const selectedGroups = this.state.groups.filter((group, idx) => this.groupSelectedForDownload(idx))

    for (let i = 0; i < selectedGroups.length; i++) {
      const group = selectedGroups[i]
      await copyGroupFromSession(group._id, selectedPage, sendLink)
      await this.loadVideos()
    }

    const emptyRoleIdRecords = []

    if(this.state.limeliteChecked){

      const submissions = []
      selectedGroups.forEach(group => {

        const mediaArray = []

        mediaArray.push({
          fileKey: group.thumbnail,
          fileName: group.thumbnail,
          mimeType: this.getMimeType(group.thumbnail)
        })
        
        group.videos.forEach(video => {
          mediaArray.push({
            fileKey: video.uri,
            fileName: video.uri,
            mimeType: 'video/mp4'
          })
        })

        group.records.forEach(record => {

          const mediaObjForAvatar = {
            fileKey: record.avatar,
            fileName: record.avatar,
            mimeType: this.getMimeType(record.avatar)
          }   

          if (record?.limelite_role_id) {
            submissions.push({
              roleId: record?.limelite_role_id,
              folder: this.state.newPostingPage.name,
              personalInfo: {
                firstName: record.first_name,
                lastName: record.last_name,
                email: record.email,
                phoneNumber: record.phone || '1111111111',
                clothesType: record?.clothes_type || null,
                sizes: record.clothes_size_object ? JSON.parse(record.clothes_size_object) : {} ,
                height: record?.height || null,
              },
              medias: record.avatar ? [...mediaArray, mediaObjForAvatar] : [...mediaArray]
            })
          } else {
            emptyRoleIdRecords.push(record)
          }
        })
      })

      if (emptyRoleIdRecords.length > 0) {
        const continue_confirm = window.confirm(`Following talents doesn't have limelite role assigned.
${emptyRoleIdRecords.map(r => `${r.first_name} ${r.last_name}`).join('\n')}

Do you want to continue?
        `)
        if (!continue_confirm) {
          this.setState({ loading: false })
          return
        }
      }

      const mediaObject = {
        projectId: this.state.studio?.limelite_project_id,
        submissions
      }

      await updateLimeLiteFolder(mediaObject)
    }

    this.setState({
      loading: false,
      selectedForUploads: [],
    })
  }

  handleGroupsViewed = async (viewed) => {
    this.setState({ loading: true })
    const selectedGroups = this.state.groups.filter((group, idx) => this.groupSelectedForDownload(idx))
    for (let i = 0; i < selectedGroups.length; i++) {
      const group = selectedGroups[i]
      await updateGroupViewed(group._id, viewed)
    }
    this.setState({
      selectedForUploads: []
    })
    this.loadVideos()
  }

  setNewPostingPage = (pp) => {
    this.setState({
      newPostingPage: pp
    })
  }

  handlePostingPageSubmit = async (postingPage = {}, studio_id) => {
    const { postingPages } = this.state
    const name = postingPage.name
    const names = postingPages.map(p => p.name)
    const originalPP = postingPages.find(p => p._id === postingPage._id)
    if (names.includes(name)
      && (!originalPP || originalPP && originalPP.name !== postingPage.name)
    ) {
      window.alert(`You already have the posting page ${name}`)
      return
    }
    await createPage({
      name,
      studio: studio_id
    })
    await this.loadPostingPages(studio_id)
    this.setNewPostingPage(null)
  }

  searchSessionTalents = async (name) => {
    this.setState({ loadingTalentRecords: true })
    const data = await fetchCheckInList(this.state.session._id)
    this.setState({
      loadingTalentRecords: false,
      sessionTalentOptions: data.filter(talent => {
        return talent.first_name.includes(name) || talent.last_name.includes(name) || `${talent.first_name} ${talent.last_name}`.includes(name)
      }).map(talent => ({
        ...talent,
        full_name: `${talent.first_name} ${talent.last_name}`
      }))
    })
  }

  allGroupsSelected = (checkTab) => {
    if (checkTab !== this.state.tab || this.state.videos.length === 0) {
      return false
    }
    return this.state.selectedForUploads.length === this.state.videos.length
  }

  toggleAllGroupSelect = (select) => {

    const filtered = this.filterGroupFunction()
    let selectedVideos = []

    filtered.forEach( group => {
      group.videos.forEach(v => {
        if(selectedVideos.includes(v.uri)){
          return
        }
        selectedVideos.push(v.uri)
      })
    })
    this.setState({
      selectedForUploads: select ? selectedVideos : []
    })
  }

  isGroupViewed = (groupId) => {
    return this.state.pageFeedbacks.filter(f => f.feedback).map(f => f.ref_id).includes(groupId)
  }

  getVideoImage = async () => {
    const videoElem = document.querySelector('#active-player > video')
    const imgData = getVideoScreenshot(videoElem)
    const file = dataURLtoFile(imgData, `${new Date()}.png`)
    const res = await uploadFile(file)
    return res.key
  }

  setGroupImageFromCapture = async (groupId, name, recordIds) => {
    const imgKey = await this.getVideoImage()
    await updateGroup(groupId, { name, name, thumbnail: imgKey }, recordIds)
    this.loadVideos()
  }

  setAvatarFromCapture = async (recordId) => {
    const imgKey = await this.getVideoImage()
    await updateManyRecords([recordId], { avatar: imgKey })
    this.loadVideos()
  }

  filterGroupFunction = () => {
    const { groups, filter, roleFilter, hideViewed, hideCopied, pageFeedbacks } = this.state

    const filteredGroup = groups.filter(group => {
      const groupRecords = []
      group.videos.forEach(video => {
        video.group.records.forEach(record => {
          const recordIds = groupRecords.map(r => r._id)
          if (!recordIds.includes(record._id)) {
            groupRecords.push(record)
          }
        })
      })
      const feedbacks = []
      groupRecords.forEach(record => {
        Object.values(record.feedbacks || {}).forEach(v => feedbacks.push(v))
      })
      let filterMatch = filter === 'all'
      let roleMatch = roleFilter === 'all'
      let viewedMatch = true
      let copiedMatch = true
      if (filter !== 'all') {
        const feedbackMatchGroups = pageFeedbacks.filter(f => f.feedback === filter).map(f => f.ref_id)
        filterMatch = feedbackMatchGroups.includes(group._id)
      }
      if (roleFilter !== 'all') {
        roleMatch = groupRecords.map(r => r.role).includes(roleFilter)
      }
      if (hideViewed && this.state.tab !== TABS.TAB) {
        viewedMatch = !this.isGroupViewed(group._id)
      }
      if (hideCopied) {
        copiedMatch = !group.copied
      }
      return filterMatch && roleMatch && viewedMatch && copiedMatch
    })
    return filteredGroup
  }

  render() {
    const {
      studio,
      tab,
      session,
      groups,
      countPerRow,
      activeItem,
      activeRidx,
      activeGidx,
      videoDates,
      groupRecords,
      twrGroupRecords,
      selectedForUploads,
      selectedGroup,
      selectedPage,
      showPageCopyModal,
      postingPages,
      newPostingPage,
      selectedTalentRecords,
      loadingTalentRecords,
      sessionTalentOptions,
      showNotification,
      notification,
      filter,
      roleFilter,
      hideViewed,
      hideCopied,
      pageFeedbacks,
      showCreatePostingPageModal
    } = this.state

    let rows = []

    if (!studio) {
      return <div>Loading...</div>
    }

    const roles = []
    groups.forEach(group => {
      group.videos.forEach(video => {
        video.group.records.forEach(record => {
          if (!roles.includes(record.role)) {
            roles.push(record.role)
          }
        })
      })
    })

    const filteredGroup =  this.filterGroupFunction()

    for (let i = 0, l = filteredGroup.length; i < l; i += countPerRow) {
      rows.push(filteredGroup.slice(i, i + countPerRow))
    }

    const rowWidth = countPerRow * (itemWidth + 32)
    const activeGroup = this.state.groups[activeGidx]

    const combinedGroupRecords = groupRecords.concat(twrGroupRecords)

    function pad(n) {
      return (n < 10) ? ("0" + n) : n;
    }

    return (
      <div className="video-app">
        <div className={`loading ${this.state.loading ? 'show' : ''}`}>
          <div className='d-flex flex-column align-items-center text-danger bg-white px-4 pt-3 pb-1 border-danger border'>
            <FaSpinner size='32px' className='spinning' />
            <span className='h4'>Processing...</span>
            <span className="uploading-percent-indicator" />
          </div>
        </div>
        <div className="video-header">
          <h2 className="mb-0 review-page-title h-32-400">
            {studio.name}<br />
            <small><small>{session.name} Video Review</small></small>
          </h2>
          <div className="d-flex align-items-center download-selected">
            <SyncComponent studio={studio} session={session} />
            <label
              className="ml-2 mb-0 primary-solid-button text-white h-14-600"
              onClick={async () => {
                await this.loadPostingPages()
                this.setState({
                  newPostingPage: {},
                  showCreatePostingPageModal: true
                })
              }}
            >
              <FaPlus className="mr-2 mt-n1" />
              Add New Posting Page
            </label>
          </div>
          <div className="pp-menu">
            {postingPages.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className='primary-solid-button text-white h-14-600'>
                  Posting Pages
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {postingPages.map(pp => (
                    <Dropdown.Item
                      key={pp._id}
                      href={`/posting-page/${studio.uri}/${pp._id}`}
                      target="_blank"
                    >
                      {pp.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <ul className="tab-menu nav nav-tabs mt-2 align-items-end">
          <li className="nav-item">
            <a
              className={`nav-link h5 mb-0 h-14-400 ${tab === TABS.VIDEOS ? 'active' : 'text-danger'}`}
              href="#"
              onClick={() => this.changeTab(TABS.VIDEOS)}
            >
              <input
                type="checkbox"
                className="mr-2"
                disabled={tab === TABS.ARCHIVED}
                checked={this.allGroupsSelected(TABS.VIDEOS)}
                onChange={(ev) => this.toggleAllGroupSelect(ev.target.checked)}
              />
              Videos
            </a>
            <div className={`${tab === TABS.VIDEOS ? 'active' : null} horizontal-line`}/>
          </li>
          {VIDEO_REVIEW_PERMISSIONS.CAN_VIEW_ARCHIVE() &&
            <li className="nav-item">
              <a
                className={`nav-link h5 mb-0 h-14-400 ${tab === TABS.ARCHIVED ? 'active' : 'text-danger'}`}
                href="#"
                onClick={() => this.changeTab(TABS.ARCHIVED)}
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  disabled={tab === TABS.VIDEOS}
                  checked={this.allGroupsSelected(TABS.ARCHIVED)}
                  onChange={(ev) => this.toggleAllGroupSelect(ev.target.checked)}
                />
                Archived
              </a>
              <div className={`${tab === TABS.ARCHIVED ? 'active' : null} horizontal-line`}/>
            </li>}
          <li className="nav-item">
            <a
              className={`nav-link h5 mb-0 h-14-400 ${tab === TABS.VIEWED ? 'active' : 'text-danger'}`}
              href="#"
              onClick={() => this.changeTab(TABS.VIEWED)}
            >
              Viewed
            </a>
            <div className={`${tab === TABS.VIEWED ? 'active' : null} horizontal-line`}/>
          </li>
          <li className="nav-item filter-bar-wrapper">
            <div className="filter-bar">
              <div className="mr-2">
                <div className='d-flex align-items-center mr-2'>
                  <Switch
                    checkedIcon={null} uncheckedIcon={null}
                    onColor="#ee514f"
                    height={16}
                    width={32}
                    checked={this.state.showAvatar}
                    onChange={v => { this.setState({ showAvatar: v })}}
                  />
                  <span className='ml-2'>Show Headshot</span>
                </div>
                <div className='d-flex align-items-center mr-2'>
                  <Switch
                    checkedIcon={null} uncheckedIcon={null}
                    onColor="#ee514f"
                    height={16}
                    width={32}
                    checked={this.state.hideCopied}
                    onChange={this.setHideCopied}
                  />
                  <span className='ml-2'>Hide Copied</span>
                </div>
                {this.state.tab !== TABS.VIEWED && (
                  <div className='d-flex align-items-center mr-2'>
                    <Switch
                      checkedIcon={null} uncheckedIcon={null}
                      onColor="#ee514f"
                      height={16}
                      width={32}
                      checked={this.state.hideViewed}
                      onChange={this.setHideViewed}
                    />
                    <span className='ml-2'>Hide Viewed</span>
                  </div>
                )}
              </div>
              <div className="mr-2">
                <select
                  key={roleFilter}
                  className="form-control action-button h-14-600"
                  value={roleFilter}
                  onChange={ev => {
                    this.setRoleFilter(ev.target.value)
                  }}
                >
                  <option key="all" value="all">
                    All Roles
                  </option>
                  {roles.map(role => (
                    <option key={role} value={role}>
                      {role || '--no role--'}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mr-2">
                <select
                    className="form-control action-button h-14-600"
                    value={filter}
                    onChange={ev => {
                      this.setFilter(ev.target.value)
                    }}
                >
                    <option key="all" value="all">All Feedback</option>
                    <option key="yes" value="yes">Yes</option>
                    <option key="no" value="no">No</option>
                    <option key="maybe" value="maybe">Maybe</option>
                    <option key="reshoot" value="reshoot">Reshoot</option>
                </select>    
              </div>
              {selectedForUploads.length > 0 && (
                <Dropdown className='ml-2'>
                  <Dropdown.Toggle>
                    <FaList />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {selectedForUploads.length > 0 && [
                      <Dropdown.Item
                        key="0"
                        className="btn btn-primary"
                        onClick={async () => {
                          await this.loadPostingPages()
                          this.setState({
                            showPageCopyModal: true
                          })
                        }}
                      >
                        <FaCopy className="mr-2 mt-n1" />
                        Copy to Posting page
                      </Dropdown.Item>,
                      <Dropdown.Item key="1" className="btn btn-primary" onClick={() => this.downloadAllVideos()} >
                        <FaDownload className="mr-2 mt-n1" />
                        Download Selected
                      </Dropdown.Item>
                    ]}
                    {selectedForUploads.length > 0 && (
                      <Dropdown.Item className='btn btn-primary mr-2' onClick={() => {
                        this.handleGroupsViewed(this.state.tab !== TABS.VIEWED)
                      }}>
                        {this.state.tab === TABS.VIEWED
                          ? <FaEyeSlash className="mr-2 mt-n1" />
                          : <FaEye className="mr-2 mt-n1" />}
                        Mark as {this.state.tab === TABS.VIEWED ? 'Not' : ''} Viewed
                      </Dropdown.Item>
                    )}
                    {selectedForUploads.length > 0 && this.state.tab !== TABS.ARCHIVED && (
                      <Dropdown.Item className='btn btn-primary mr-2' onClick={() => {
                        this.handleArchiveManyGroup()
                      }}>
                        <FaArchive className="mr-2 mt-n1" />
                        Archive Selected
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </li>
        </ul>
        <div className="video-wrapper">
          {rows.length === 0 && <div className="p-5">No videos available </div>}
          {rows.map((row, ridx) => {
            return (
              [
                <div className="video-row" key={ridx} style={{ width: `${rowWidth}px` }}>
                  {row.map(group => {
                    const groupVideos = group.videos.map(v => v._id);
                    const toArchive = !(tab === TABS.ARCHIVED);
                    const thumbImage = this.state.showAvatar
                      ? group.records[0]?.avatar || group.thumbnail
                      : group.thumbnail;
                    return (
                      <div
                        key={group.idx}
                        className={`mx-2 video-item ${activeGidx === group.idx ? 'active' : ''}`}
                        style={{
                          width: itemWidth
                        }}
                      >
                        <div
                          className="preview-wrapper"
                          onClick={() => {
                            this.handleGroupItemClick(ridx, group.idx);
                          }}
                        >
                          <img
                            loading='lazy'
                            className="dummy-player"
                            src={static_root + thumbImage}
                            onError={(ev) => {
                              ev.target.src = static_root + group.records[0]?.avatar;
                            }}
                            alt='thumbnail'
                          />
                        </div>
                        <div className="d-flex align-items-center py-2">
                          <input
                            type="checkbox"
                            checked={this.groupSelectedForDownload(group.idx)}
                            className="mr-2"
                            onChange={(ev) => this.toggleGroupSelectedForDownload(group.idx, ev.target.checked)}
                          />
                          <span className='h-12-600 ls-2'>{group.name}</span>
                          {VIDEO_REVIEW_PERMISSIONS.CAN_UPDATE_GROUP() && group._id &&
                            <label
                              className="mb-0 mx-2"
                              onClick={ev => {
                                ev.stopPropagation();
                                ev.preventDefault();
                                this.setState({
                                  selectedGroup: group,
                                  selectedTalentRecords: ((group.videos[0].group || {}).records || []).map(talent => ({
                                    ...talent,
                                    full_name: `${talent.first_name} ${talent.last_name}`
                                  }))
                                });
                              }}
                            >
                              <EditIconSmall />
                            </label>}
                          {this.isGroupViewed(group._id) && <label className='mb-0'><FaEye /></label>}
                          {VIDEO_REVIEW_PERMISSIONS.CAN_ARCHIVE() &&
                            <label
                              className="mb-0 ml-auto"
                              onClick={() => {
                                this.handleGroupArchive(groupVideos, toArchive);
                              }}
                              title={toArchive ? 'Archive' : 'Restore'}
                            >
                              {toArchive ? <ArchiveIcon /> : <FaTeethOpen />}
                            </label>}
                        </div>
                      </div>
                    )
                  })}
                </div>,
                ridx === activeRidx && activeGroup ?
                  <div className="d-flex flex-column active-group-row p-3" key="active-field">
                  {activeItem ? (
                    <div className="row player-row mb-2">
                      <div className="col-lg-8 d-flex flex-column position-relative">
                        <div className="video-player-wrapper d-flex position-relative">
                          <ReactPlayer
                            controls={true}
                            url={static_root + activeItem.uri}
                            key={activeItem.uri}
                            autoPlay
                            id="active-player"
                            height="100%"
                            width="100%"
                          />
                          <button 
                            className='btn btn-primary btn-sm position-absolute' 
                            style={{ top: '10px', right: '10px', opacity: '0.8' }}
                            onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                            onMouseLeave={(e) => e.currentTarget.style.opacity = '0.8'}
                            onClick={() => {
                              this.setGroupImageFromCapture(activeGroup._id, activeGroup.name, activeGroup.records.map(r => r._id))
                            }}
                          >
                            Set Group Thumbnail
                          </button>
                        </div>
                        <div
                          key={activeGidx}
                          className="d-flex align-items-start group-videos-wrapper py-2"
                          style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
                        >
                          {activeGroup.videos.map((video, index) => {
                            return (
                              <div
                                key={video.uri}
                                className={`mx-0 mb-2 mr-2 video-item ${activeItem.uri === video.uri ? 'active' : ''}`}
                                style={{ display: 'inline-block' }}
                              >
                                <div className="index-indicator">
                                  {pad(index + 1)}
                                </div>
                                <div
                                  style={{
                                    width: thumbWidth
                                  }}
                                >
                                  <div
                                    className="preview-wrapper"
                                    onClick={() => this.setState({ activeItem: video })}
                                  >
                                    <img
                                      className="dummy-player dummy-video"
                                      src={static_root + video.thumbnail}
                                      onError={(ev) => {
                                        ev.target.src = static_root + activeGroup.records[0].avatar;
                                      }}
                                      alt='thumbnail'
                                    />
                                  </div>
                                  <div className="d-flex">
                                    {VIDEO_REVIEW_PERMISSIONS.CAN_ARCHIVE() &&
                                      <label
                                        className="mb-0 ml-2"
                                        onClick={() => {
                                          this.handleArchiveVideo(video._id, !video.is_archived);
                                        }}
                                        title={video.is_archived ? 'Restore' : 'Archive'}
                                      >
                                        {video.is_archived ? <FaTeethOpen /> : <FaArchive />}
                                      </label>}
                                    {video.is_archived && USER_TYPE.IS_SUPER_ADMIN() && (
                                      <label
                                        className="ml-auto mb-0"
                                        onClick={() => this.handleVideoDelete(video._id)}
                                        title="Delete"
                                      >
                                        <FaTrash />
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                          {tab !== TABS.ARCHIVED && VIDEO_REVIEW_PERMISSIONS.CAN_ADD_VIDEO() && (
                            <div
                              style={{
                                width: thumbWidth,
                                minWidth: thumbWidth,
                                alignSelf: 'stretch'
                              }}
                              className="pb-4"
                            >
                              <div className="video-uploader pt-4 px-3 mr-2 h-100">
                                <span className='h-12-600'>Upload New Video</span>
                                <input
                                  key={activeGroup.videos.length}
                                  type="file"
                                  accept="video/*"
                                  onChange={ev => {
                                    this.uploadNewVideo(ev.target.files[0]);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div key="info" className="col-lg-4 info col-12">
                        {combinedGroupRecords.map(record => (
                          <div className="talent-summary d-flex align-items-center" key={record._id}>
                            <PersonCardV
                              record={record}
                              refId={activeGroup._id}
                              pageId={this.session_id}
                              showInlineComments={true}
                              setAvatarFromCapture={this.setAvatarFromCapture}
                            />
                          </div>
                        ))}
                        {combinedGroupRecords.length === 0 &&
                          <div className="talent-summary">
                            No talent information available
                          </div>}
                        {activeGroup.note_to_casting_director && (
                          <div className='mt-2'>
                            <strong>Submission Note</strong>
                            <p>
                              {activeGroup.note_to_casting_director}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  </div>
                  : null
              ]
            );
          })}
        </div>
        <Footer />
        <Modal
          show={!!selectedGroup._id}
          onHide={() => {
            this.setState({
              selectedGroup: {}
            })
          }}
        >
          <Modal.Header closeButton>
            <h5 className="mb-0">
              Edit group
            </h5>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Group Name"
              value={selectedGroup.name}
              onChange={ev => {
                this.setState({
                  selectedGroup: {
                    ...this.state.selectedGroup,
                    name: ev.target.value
                  }
                })
              }}
            />
            <input
              type="file"
              className="form-control mb-2"
              onChange={ev => {
                this.setState({
                  selectedGroup: {
                    ...this.state.selectedGroup,
                    thumbnail: ev.target.files[0]
                  }
                })
              }}
            />
            <AsyncTypeahead
              id="talent-record-select"
              selected={selectedTalentRecords}
              onChange={value => {
                this.setState({
                  selectedTalentRecords: value
                })
              }}
              isLoading={loadingTalentRecords}
              labelKey="full_name"
              minLength={2}
              onSearch={this.searchSessionTalents}
              options={sessionTalentOptions}
              placeholder="Search for a talent user..."
              multiple
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={selectedGroup && !selectedGroup.name}
              className="btn btn-primary"
              onClick={async () => {
                await updateGroup(selectedGroup._id, selectedGroup, selectedTalentRecords.map(r => r._id))
                this.setState({
                  selectedGroup: {}
                })
                await this.loadVideos()
                if (activeRidx !== -1) {
                  this.handleGroupItemClick(activeRidx, activeGidx)
                }
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showPageCopyModal}
          onHide={() => {
            this.setState({
              showPageCopyModal: false,
              limeliteChecked: false
            })
          }}
        >
          <Modal.Header closeButton>
            <h5 className="mb-0">
              Copy selected group
            </h5>
          </Modal.Header>
         <Modal.Body>
            <CreatePostingPage
              postingPages={this.state.postingPages}
              newPostingPage={newPostingPage}
              setNewPostingPage={this.setNewPostingPage}
            />
            {USER_TYPE.IS_SUPER_ADMIN() && (
              <div>
                <label className="d-flex align-items-center mt-2">
                  <input id="send-audition-link" type="checkbox" className="mr-2" />
                  Send audition link email?
                </label>
              </div>
            )}
            {VIDEO_REVIEW_PERMISSIONS.CAN_COPY_TO_LIMELITE() && studio?.limelite_project_id && (
              <div className='folder-selector-container'>
                <label className="d-flex align-items-center mt-2" htmlFor='copy-to-limelite'>
                  <input 
                    id="copy-to-limelite" 
                    type="checkbox" 
                    className="mr-2"
                    checked={this.state.limeliteChecked}
                    onChange={() =>
                      this.setState({
                        limeliteChecked: !this.state.limeliteChecked
                      })
                    }
                  />
                  Copy to Limelite
                </label>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={!newPostingPage?.name}
              className="btn btn-primary"
              onClick={this.handleGroupsCopy}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showCreatePostingPageModal}
          onHide={() => {
            this.setNewPostingPage(null)
            this.setState({
              showCreatePostingPageModal: false
            })
          }}
        >
          <Modal.Header closeButton className="align-items-baseline">
            <h4 className="mb-0 mr-3">
              {newPostingPage && newPostingPage._id ? `Update ${newPostingPage.name}` : 'Create New Posting Page'}
            </h4>
          </Modal.Header>
          <Modal.Body>
            {newPostingPage && (
              <input
                type="text"
                className="form-control mb-3"
                value={newPostingPage.name}
                onChange={ev => {
                  this.setNewPostingPage({
                    ...newPostingPage,
                    name: ev.target.value
                  })
                }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={newPostingPage && !newPostingPage.name}
              className="btn btn-primary"
              onClick={() => {
                this.handlePostingPageSubmit(newPostingPage, studio._id)
                this.setState({
                  showCreatePostingPageModal: false
                })
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={!!showNotification}
          onHide={() => {
            this.setState({
              showNotification: ''
            })
          }}
          className="notification-modal"
        >
          <Modal.Header closeButton>
            <h5 className="mb-0">
              {noticeTitle}
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="notification-content" dangerouslySetInnerHTML={{ __html: notification[noticeField] }} />
            <div className="mt-2">
              <button className="btn btn-primary" onClick={() => {
                window.localStorage.setItem(noticeUpdatedAtField, notification[noticeUpdatedAtField])
                this.setState({
                  showNotification: ''
                })
              }}>
                Ok, Got it.
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default VideoPage
