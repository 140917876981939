import React from "react";
import { static_root, uploadFile } from "../../../services";
import { CloseIconBlack } from "../../../assets/icons/session";

import "./style.scss";
import SelectSize from "../../../components/size-select";

const EditInfo = ({
  setFirstName,
  firstName,
  setLastName,
  lastName,
  setPhoneNumber,
  phoneNumber,
  setAgent,
  agent,
  setEmail,
  email,
  setPhotos,
  photos,
  setShowEditInfo,
  setAgentPhone,
  agentPhone,
  validateProfileInfo,
  updateUser,
  isKiosk = false,
  sizeObject,
  setSizeObject,
  clothesType,
  setClothesType,
  setFt,
  ft,
  setInches,
  inches
}) => {
  const [saving, setSaving] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);

  const onSaveProfile = async () => {
    try {
      const v = validateProfileInfo()
      if (!v) {
        return
      }
      setSaving(true);
      await updateUser(); 

      setSaving(false);
      setShowEditInfo(false);
    } catch (e) {
      setSaving(false);
    }
  };

  const uploadPhoto = async (files) => {
    setUploading(true);
    const addedPhotos = [];
    for (let i = 0; i < Math.min(4 - photos.length, files.length); i++) {
      const res = await uploadFile(files[i]);
      addedPhotos.push(res.key);
    }
    setPhotos(photos.concat(addedPhotos));
    setUploading(false);
    document.querySelector("#photo").value = [];
  };

  const onSelectSize = (e, type) => {
    const sizeObjectCopy = {...sizeObject}
    sizeObjectCopy[type] = { value: e.value, label: e.label }
    setSizeObject(sizeObjectCopy)
  }

  return (
    <div className="edit-info">
      {isKiosk ? (
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="h-16-600 ls-2">Add Your Information</h3>
        </div>
      ) : 
      (
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h3 className="h-16-600 ls-2">Editing Your Information</h3>
            <h4 className="h-12-400 lh-150 text-secondary">
              Any changes you save here will be reflected in your profile.
            </h4>
          </div>
          <div className="d-flex align-items-center ml-2">
            <button
              className="primary-outline-button h-12-600"
              onClick={() => setShowEditInfo(false)}
            >
              Cancel
            </button>
            <button
              className="primary-solid-button h-12-700 text-white ml-2"
              onClick={onSaveProfile}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      )}

      <form id="contactForm">
        <div>
          <label className="h-12-600 ls-2">First Name *</label>
          <input
            value={firstName}
            onChange={(ev) => setFirstName(ev.target.value)}
            type="text"
            name="firstname"
            id="firstname"
            className="form-control"
            required
          />
        </div>
        <div>
          <label className="h-12-600 ls-2">Last Name *</label>
          <input
            value={lastName}
            onChange={(ev) => setLastName(ev.target.value)}
            type="text"
            name="lastname"
            id="lastname"
            className="form-control"
            required
          />
        </div>

        <div>
          <label className="h-12-600 ls-2">Phone * (for SMS audition instructions)</label>
          <input
            value={phoneNumber}
            onChange={(ev) => setPhoneNumber(ev.target.value)}
            type="text"
            name="phone"
            id="phone"
            className="form-control"
            required
          />
        </div>
        <div>
          <label className="h-12-600 ls-2">Email Address *</label>
          <input
            disabled={!isKiosk}
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            type="email"
            name="email"
            id="email"
            className="form-control"
            required
          />
        </div>
        <div>
          <label className="h-12-600 ls-2">Agent</label>
          <input
            value={agent}
            onChange={(ev) => setAgent(ev.target.value)}
            type="text"
            name="agent"
            id="agent"
            className="form-control"
          />
        </div>
        <div>
          <label className="h-12-600 ls-2" htmlFor="agent_phone">Agent Phone</label>
          <input
            value={agentPhone}
            onChange={(ev) => setAgentPhone(ev.target.value)}
            type="text"
            name="agent_phone"
            id="agent_phone"
            className="form-control"
            required
          />
        </div>
        {isKiosk && (
          <div className="select-grid-item">
            <div className="input-row align-items-center justify-content-between">
              <label className="h-12-600">Height</label>
              <div className="d-flex align-items-center">
                <div className="div-input">
                  <input 
                    type="text" 
                    name="ft" 
                    id="ft" 
                    className="height-input" 
                    value={ft}
                    onChange={(ev) => setFt(ev.target.value)}/>
                  <div className="h-12-600 indicator">ft</div>
                </div>
                <div className="div-input ml-2">
                  <input
                    type="text"
                    name="inches"
                    id="inches"
                    className="height-input"
                    value={inches}
                    onChange={(ev) => setInches(ev.target.value)}/>
                  <div className="h-12-600 indicator">in</div>
                </div>
              </div>
            </div>
            <SelectSize 
              sizeObject={sizeObject}
              setSizeObject={setSizeObject}
              onSelectSize={onSelectSize}
              clothesType={clothesType}
              setClothesType= {setClothesType}
            />
          </div>
        )}
      </form>
      {!isKiosk && (
        <div className="bottom">
          <div>
            <h3 className="h-12-600">Headshots</h3>
            <label className="h-12-400 secondary-color">
              600px x 600px JPG/PNG images recommended (at most 4 photos)
            </label>
          </div>
          {photos.length > 0 && (
            <div className="checkin-headshot-wrapper">
              {photos.map((photo, index) => {
                return (
                  <div key={index} className="checkin-headshot-wrap" id={index}>
                    <img
                      src={static_root + photo}
                      className="checkin-headshot"
                      alt="profile"
                      id={index}
                    />
                    <span
                      className="remove-photo-item"
                      onClick={() => {
                        setPhotos(photos.filter((p) => p !== photo));
                      }}
                    >
                      <CloseIconBlack />
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          <input
            key={uploading}
            type="file"
            id="photo"
            accept="image/*"
            multiple
            onChange={(ev) => uploadPhoto(ev.target.files)}
            hidden
          />
          <button
            disabled={uploading || !(photos.length < 4)}
            className="checking-upload-button h-14-600 primary-outline-button mt-3"
            onClick={() => {
              document.querySelector("#photo").click();
            }}
          >
            {uploading ? "Uploading..." : "Upload Headshots"}
          </button>
        </div>
      )}
    </div>
  );
};

export default EditInfo;
