import React, { useState, useEffect, useRef, useCallback, useReducer, useContext } from "react";
import { Button, Container, Row, Col, Form } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react'
import _ from 'lodash'
import { TINYMCE_KEY } from '../../../constants'
import { apiGetAllEmailTemplates, apiUpdateEmailTemplate, createEmailTemplate, deleteEmailTemplate } from '../../../services'
import { ShowLoadingContext } from '../../../Context'
import { FaPlus, FaTrash } from "react-icons/fa";

const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return action.payload
    case 'update':
      const idx = state.findIndex(it=>it._id === action.payload._id)
      const temp = [...state]
      temp[idx] = action.payload
      return temp
    default:
      return state
  }
}

const query = new URLSearchParams(window.location.search)
const isDebug = query.get('debug') === 'true'

export default () => {
  const [showEmailTemplate, setShowEmailTemplate] = useState(null)
  const [emailTemplates, dispatch] = useReducer(reducer, [])
  const [subject, setSubject] = useState('')
  const [html, setHtml] = useState('')
  const [desc, setDesc] = useState('')
  const toggleLoading = useContext(ShowLoadingContext)

  const editorRef = useRef(null)
  const loadAllEmailTempaltes = useCallback(async () => {
    const res = await apiGetAllEmailTemplates()
    if(res.length > 0) {
      setShowEmailTemplate(res[0]._id)
      setSubject(res[0].subject)
      setHtml(res[0].html)
      setDesc(res[0].description)
    }
    dispatch({
      type: 'set',
      payload: res
    })
  }, [])
  
  useEffect(()=>{
    loadAllEmailTempaltes()
  }, [loadAllEmailTempaltes])
 
  const onUpdateBtnClick = async () => {
    toggleLoading(true)
    const htmlContent = editorRef.current.getContent()
    const formData = new FormData()
    formData.append('html', htmlContent)
    formData.append('subject', subject)
    formData.append('desc', desc)
    const updated = await apiUpdateEmailTemplate(showEmailTemplate, formData)
    dispatch({type: 'update', payload: updated})
    toggleLoading(false)
  }
  
  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          {emailTemplates.map(it => (
            <div
              key = {it._id}
              className={'d-flex ' + (showEmailTemplate === it._id ?'bg-danger' : '')}
            >
              <Button
                block
                variant="text"
                className={"text-left text-capitalize " + (showEmailTemplate === it._id ?'text-white' : '')}
                onClick={() => {
                  setShowEmailTemplate(it._id)
                  setSubject(it.subject)
                  setHtml(it.html)
                  setDesc(it.description)
                }}
              >
                {it.name.split('_').join(' ').toLowerCase()}
              </Button>
              {isDebug && (
                <Button
                  variant="text" className={(showEmailTemplate === it._id ?'text-white' : '')}
                  onClick={async () => {
                    await deleteEmailTemplate()
                    loadAllEmailTempaltes()
                  }}
                >
                  <FaTrash />
                </Button>
              )}
            </div>
          ))}
          {isDebug && (
            <Button variant="text" onClick={async () => {
              const title = window.prompt('Email template title')
              await createEmailTemplate({ name: title })
              loadAllEmailTempaltes()
            }}>
              <FaPlus />
            </Button>
          )}
          
        </Col>
        <Col key = {showEmailTemplate} md={10}>
          <Form.Group>
            <Form.Label>Subject</Form.Label>
            <Form.Control
              value={subject}
              onChange={e=>{setSubject(e.target.value)}}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Content</Form.Label>
            <div className="d-flex">
              <Editor
                key={showEmailTemplate}
                apiKey={TINYMCE_KEY}
                onInit={(evt, editor) => editorRef.current = editor}
                init={{
                  height: '50vh',
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                value={html}
                onEditorChange={content => {
                  setHtml(content)
                }}
              />
              <textarea
                className="form-control w-50"
                style={{
                  height: '50vh',
                  whiteSpace: 'pre-line',
                  fontFamily: 'consolas'
                }}
                value={html}
                onChange={ev => {
                  setHtml(ev.target.value)
                }}
              ></textarea>
            </div>
            <p
              className="mt-3"
              dangerouslySetInnerHTML={{__html: desc}}
            ></p>
            <textarea
              className={`form-control ${isDebug ? '' : 'd-none'}`}
              value={desc}
              onChange={ev => {
                setDesc(ev.target.value)
              }}
            ></textarea>
          </Form.Group>
          
          <div className="my-2">
            <Button
              className="mr-3"
              onClick={onUpdateBtnClick}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
