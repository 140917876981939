import { getUser } from './services'

const user = getUser()

export const VERSION = 'v1.03'

const USER_TYPES = {
  SUPER_ADMIN: 'super_admin',
  CASTING_DIRECTOR: 'casting_director',
  SESSION_MANAGER: 'session_manager',
  CLIENT: 'client',
  TALENT: 'talent'
}

const USER_TYPE_TEXT = {
  [USER_TYPES.SUPER_ADMIN]: 'Super Admin',
  [USER_TYPES.CASTING_DIRECTOR]: 'Casting Director',
  [USER_TYPES.SESSION_MANAGER]: 'Session Manager',
  [USER_TYPES.CLIENT]: 'Client',
  [USER_TYPES.TALENT]: 'Talent',
}

const PROJECT_TYPES = {
  DEFAULT: 'default',
  CREATOR: 'creator'
}

const USER_TYPE = {
  IS_TALENT: () => user && user.user_type === USER_TYPES.TALENT,
  IS_CLIENT: () => user && user.user_type === USER_TYPES.CLIENT,
  IS_SUPER_ADMIN: () => user && user.user_type === USER_TYPES.SUPER_ADMIN,
  CASTING_DIRECTOR: () => user && user.user_type === USER_TYPES.CASTING_DIRECTOR,
  SESSION_MANAGER: () => user && user.user_type === USER_TYPES.SESSION_MANAGER,
}

const VIDEO_REVIEW_PERMISSIONS = {
  CAN_ARCHIVE: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_ADD_VIDEO: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_VIEW_ARCHIVE: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_UPDATE_GROUP: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_LEAVE_FEEDBACK: () => {
    return [USER_TYPES.CLIENT].includes(user.user_type)
  },
  CAN_COPY_TO_LIMELITE: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  }
}

const STUDIO_LIST_PERMISSIONS = {
  CAN_VIEW_ONBOARD: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_VIEW_CLIENTPAGE: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_VIEW_VIDEO_REVIEW: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER, USER_TYPES.CLIENT].includes(user.user_type)
  },
  CAN_CREATE_STUDIO: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(user.user_type)
  },
  CAN_ARCHIVE_STUDIO: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(user.user_type)
  }
}

const POSTINGPAGE_PERMISSIONS = {
  CAN_SORT_GROUPS: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_ARCHIVE: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_ADD_VIDEO: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_VIEW_ARCHIVE: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_VIEW_CONTACT: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_UPDATE_GROUP: () => {
    return [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  },
  CAN_LEAVE_FEEDBACK: () => {
    return [USER_TYPES.CLIENT, USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR, USER_TYPES.SESSION_MANAGER].includes(user.user_type)
  }
}

const ON_DECK_STATUS = {
  ON_DECK: 'ON_DECK',
  IN_CASTING: 'IN_CASTING'
}

const ON_DECK_ORDER = {
  [ON_DECK_STATUS.ON_DECK]: 2,
  [ON_DECK_STATUS.IN_CASTING]: 1
}


const SESSION_TIME_TYPE = [
  '1st call',
  'Callback'
]

const SESSION_BOOK_TYPE = [
  'Book',
  'Hold'
]

const SESSION_LOCATION_TYPE = [
  'Virtual',
  'In Person',
  'Both',
  'Video Submission'
]

const MEETING_HOST = process.env.REACT_APP_MEETING_HOST

const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY

const WS_HOST = process.env.REACT_APP_WS_HOST


const FREELANCER_WORK_AS = {
  SESSION_RUNNER: 'session_runner',
  LOBBY_MANAGER: 'lobby_manager'
}

const FREELANCER_TIMEZONE = [
  'Pacific',
  'Mountain',
  'Central',
  'Eastern'
]

const FILE_FIELDS = [
  'size_card_pdf',
  'schedule_pdf',
  'script_pdf',
  'storyboard_pdf',
  'treatment_pdf'
]

const TALENTS_UPLOAD_CSV_HEADERS = [
  { key: 'first_name', label: 'FIRST NAME *' },
  { key: 'last_name', label: 'LAST NAME *' },
  { key: 'email', label: 'EMAIL *' },
  { key: 'phone', label: 'PHONE' },
  { key: 'agent', label: 'AGENT' },
  { key: 'role', label: 'ROLE' },
  { key: 'call_time', label: 'CALL TIME' },
  { key: 'sagnumber', label: 'SAG-AFTRA NUMBER' },
]
const TALENTS_UPLOAD_CSV_SAMPLE_DATA = [
  'John',
  'Doe',
  'johndoe@example.com',
  '1234567890',
  'Agent',
  'Comma; Man',
  'YYYY-MM-DD hh:mm aa (e.g 2024-05-01 12:00 am)',
  '###',
]

const WOMEN_DRESS_SIZES = [
  { value: "INFANT", label: "0-3 months" },
  { value: "INFANT_3_6", label: "3-6 months" },
  { value: "INFANT_6_9", label: "6-9 months" },
  { value: "INFANT_9_12", label: "9-12 months" },
  { value: "TODDLER_12_18", label: "12-18 months" },
  { value: "TODDLER_18_24", label: "18-24 months" },
  { value: "CHILD_2_3", label: "2-3 years" },
  { value: "CHILD_3_4", label: "3-4 years" },
  { value: "CHILD_4_5", label: "4-5 years" },
  { value: "CHILD_5_6", label: "5-6 years" },
  { value: "CHILD_6_7", label: "6-7 years" },
  { value: "CHILD_7_8", label: "7-8 years" },
  { value: "CHILD_8_9", label: "8-9 years" },
  { value: "CHILD_9_10", label: "9-10 years" },
  { value: "TEEN_10_12", label: "10-12 years" },
  { value: "TEEN_12_14", label: "12-14 years" },
  { value: "ADULT_32", label: "EU 32 / UK 4 / US 0" },
  { value: "ADULT_34", label: "EU 34 / UK 6 / US 2" },
  { value: "ADULT_36", label: "EU 36 / UK 8 / US 4" },
  { value: "ADULT_38", label: "EU 38 / UK 10 / US 6" },
  { value: "ADULT_40", label: "EU 40 / UK 12 / US 8" },
  { value: "ADULT_42", label: "EU 42 / UK 14 / US 10" },
  { value: "ADULT_44", label: "EU 44 / UK 16 / US 12" },
  { value: "ADULT_46", label: "EU 46 / UK 18 / US 14" },
  { value: "ADULT_48", label: "EU 48 / UK 20 / US 16" },
  { value: "ADULT_50", label: "EU 50 / UK 22 / US 18" }
];

 const WOMEN_SHOE_SIZES = [
  // Infant Shoe Sizes
  { value: "INFANT_16", label: "Infant: EU 16 / UK 0.5 / US 1" },
  { value: "INFANT_17", label: "Infant: EU 17 / UK 1.5 / US 2" },
  { value: "INFANT_18", label: "Infant: EU 18 / UK 2 / US 3" },
  { value: "INFANT_19", label: "Infant: EU 19 / UK 3 / US 4" },
  { value: "INFANT_20", label: "Infant: EU 20 / UK 4 / US 5" },
  
  // Toddler Shoe Sizes
  { value: "TODDLER_21", label: "Toddler: EU 21 / UK 4.5 / US 5.5" },
  { value: "TODDLER_22", label: "Toddler: EU 22 / UK 5 / US 6" },
  { value: "TODDLER_23", label: "Toddler: EU 23 / UK 6 / US 7" },
  { value: "TODDLER_24", label: "Toddler: EU 24 / UK 7 / US 8" },
  
  // Child Shoe Sizes
  { value: "CHILD_25", label: "Child: EU 25 / UK 8 / US 8.5" },
  { value: "CHILD_26", label: "Child: EU 26 / UK 8.5 / US 9" },
  { value: "CHILD_27", label: "Child: EU 27 / UK 9.5 / US 10" },
  { value: "CHILD_28", label: "Child: EU 28 / UK 10 / US 11" },
  { value: "CHILD_29", label: "Child: EU 29 / UK 11 / US 12" },
  { value: "CHILD_30", label: "Child: EU 30 / UK 11.5 / US 12.5" },
  { value: "CHILD_31", label: "Child: EU 31 / UK 12.5 / US 13" },
  { value: "CHILD_32", label: "EU 32 / UK 13 / US 1" },
  { value: "CHILD_33", label: "EU 33 / UK 1 / US 1.5" },
  { value: "CHILD_34", label: "EU 34 / UK 2 / US 2.5" },
  
  // Teen and Adult Shoe Sizes
  { value: "TEEN_35", label: "EU 35 / UK 2.5 / US 3" },
  { value: "TEEN_36", label: "EU 36 / UK 3.5 / US 4" },
  { value: "ADULT_37", label: "EU 37 / UK 4 / US 4.5" },
  { value: "ADULT_37_5", label: "EU 37.5 / UK 4.5 / US 5" },
  { value: "ADULT_38", label: "EU 38 / UK 5 / US 5.5" },
  { value: "ADULT_38_5", label: "EU 38.5 / UK 5.5 / US 6" },
  { value: "ADULT_39", label: "EU 39 / UK 6 / US 6.5" },
  { value: "ADULT_40", label: "EU 40 / UK 6.5 / US 7" },
  { value: "ADULT_40_5", label: "EU 40.5 / UK 7 / US 7.5" },
  { value: "ADULT_41", label: "EU 41 / UK 7.5 / US 8" },
  { value: "ADULT_42", label: "EU 42 / UK 8 / US 8.5" },
  { value: "ADULT_42_5", label: "EU 42.5 / UK 8.5 / US 9" },
  { value: "ADULT_43", label: "EU 43 / UK 9 / US 9.5" },
  { value: "ADULT_44", label: "EU 44 / UK 9.5 / US 10" },
  { value: "ADULT_44_5", label: "EU 44.5 / UK 10 / US 10.5" },
  { value: "ADULT_45", label: "EU 45 / UK 10.5 / US 11" },
  { value: "ADULT_46", label: "EU 46 / UK 11 / US 11.5" },
  { value: "ADULT_46_5", label: "EU 46.5 / UK 11.5 / US 12" },
  { value: "ADULT_47", label: "EU 47 / UK 12 / US 12.5" },
  { value: "ADULT_47_5", label: "EU 47.5 / UK 12.5 / US 13" },
  { value: "ADULT_48", label: "EU 48 / UK 13 / US 13.5" },
  { value: "ADULT_48_5", label: "EU 48.5 / UK 13.5 / US 14" },
  { value: "ADULT_49", label: "EU 49 / UK 14 / US 14.5" },
  { value: "ADULT_49_5", label: "EU 49.5 / UK 14.5 / US 15" },
  { value: "ADULT_50", label: "EU 50 / UK 15 / US 15.5" }
];

const WOMEN_BUST_SIZES = [
  // Infant Bust Sizes
  { value: "INFANT_38CM", label: "38 cm / 15 in" },
  { value: "INFANT_40CM", label: "40 cm / 16 in" },
  { value: "INFANT_42CM", label: "42 cm / 17 in" },
  { value: "INFANT_44CM", label: "44 cm / 17 in" },
  
  // Toddler Bust Sizes
  { value: "TODDLER_46CM", label: "46 cm / 18 in" },
  { value: "TODDLER_48CM", label: "48 cm / 19 in" },
  { value: "TODDLER_50CM", label: "50 cm / 20 in" },
  { value: "TODDLER_52CM", label: "52 cm / 20 in" },
  
  // Child Bust Sizes
  { value: "CHILD_54CM", label: "54 cm / 21 in" },
  { value: "CHILD_56CM", label: "56 cm / 22 in" },
  { value: "CHILD_58CM", label: "58 cm / 23 in" },
  { value: "CHILD_60CM", label: "60 cm / 24 in" },
  { value: "CHILD_62CM", label: "62 cm / 24 in" },
  { value: "CHILD_64CM", label: "64 cm / 25 in" },
  { value: "CHILD_66CM", label: "66 cm / 26 in" },
  { value: "CHILD_68CM", label: "68 cm / 27 in" },

  // Teen Bust Sizes
  { value: "TEEN_70CM", label: "70 cm / 28 in" },
  { value: "TEEN_72CM", label: "72 cm / 28 in" },
  { value: "TEEN_74CM", label: "74 cm / 29 in" },
  { value: "TEEN_76CM", label: "76 cm / 30 in" },

  // Adult Bust Sizes
  { value: "ADULT_78CM", label: "78-82 cm / 31-32 in" },
  { value: "ADULT_83CM", label: "83-87 cm / 33-34 in" },
  { value: "ADULT_88CM", label: "88-92 cm / 35-36 in" },
  { value: "ADULT_93CM", label: "93-97 cm / 37-38 in" },
  { value: "ADULT_98CM", label: "98-102 cm / 39-40 in" },
  { value: "ADULT_103CM", label: "103-107 cm / 41-42 in" },
  { value: "ADULT_108CM", label: "108-112 cm / 43-44 in" },
  { value: "ADULT_113CM", label: "113-117 cm / 45-46 in" },
  { value: "ADULT_118CM", label: "118-122 cm / 46-48 in" }
];


const WOMEN_HIP_SIZES = [
  // Infant Hip Sizes
  { value: "INFANT_40CM", label: "40 cm / 16 in" },
  { value: "INFANT_42CM", label: "42 cm / 17 in" },
  { value: "INFANT_44CM", label: "44 cm / 17 in" },
  { value: "INFANT_46CM", label: "46 cm / 18 in" },
  
  // Toddler Hip Sizes
  { value: "TODDLER_48CM", label: "48 cm / 19 in" },
  { value: "TODDLER_50CM", label: "50 cm / 20 in" },
  { value: "TODDLER_52CM", label: "52 cm / 20 in" },
  { value: "TODDLER_54CM", label: "54 cm / 21 in" },
  
  // Child Hip Sizes
  { value: "CHILD_56CM", label: "56 cm / 22 in" },
  { value: "CHILD_58CM", label: "58 cm / 23 in" },
  { value: "CHILD_60CM", label: "60 cm / 24 in" },
  { value: "CHILD_62CM", label: "62 cm / 24 in" },
  { value: "CHILD_64CM", label: "64 cm / 25 in" },
  { value: "CHILD_66CM", label: "66 cm / 26 in" },
  { value: "CHILD_68CM", label: "68 cm / 27 in" },
  { value: "CHILD_70CM", label: "70 cm / 28 in" },

  // Teen Hip Sizes
  { value: "TEEN_72CM", label: "72 cm / 28 in" },
  { value: "TEEN_74CM", label: "74 cm / 29 in" },
  { value: "TEEN_76CM", label: "76 cm / 30 in" },
  { value: "TEEN_78CM", label: "78 cm / 31 in" },

  // Adult Hip Sizes
  { value: "ADULT_84CM", label: "84-88 cm / 33-34 in" },
  { value: "ADULT_89CM", label: "89-93 cm / 35-36 in" },
  { value: "ADULT_94CM", label: "94-98 cm / 37-39 in" },
  { value: "ADULT_99CM", label: "99-103 cm / 39-41 in" },
  { value: "ADULT_104CM", label: "104-108 cm / 41-42 in" },
  { value: "ADULT_109CM", label: "109-113 cm / 43-44 in" },
  { value: "ADULT_114CM", label: "114-118 cm / 45-46 in" },
  { value: "ADULT_119CM", label: "119-123 cm / 47-48 in" },
  { value: "ADULT_124CM", label: "124-128 cm / 48-50 in" }
];


const MEN_SUIT_SIZES = [
  // Infant Suit Sizes
  { value: "INFANT_0_3", label: "0-3 months" },
  { value: "INFANT_3_6", label: "3-6 months" },
  { value: "INFANT_6_9", label: "6-9 months" },
  { value: "INFANT_9_12", label: "9-12 months" },
  
  // Toddler Suit Sizes
  { value: "TODDLER_12_18", label: "12-18 months" },
  { value: "TODDLER_18_24", label: "18-24 months" },
  
  // Child Suit Sizes
  { value: "CHILD_2_3", label: "2-3 years" },
  { value: "CHILD_3_4", label: "3-4 years" },
  { value: "CHILD_4_5", label: "4-5 years" },
  { value: "CHILD_5_6", label: "5-6 years" },
  { value: "CHILD_6_7", label: "6-7 years" },
  { value: "CHILD_7_8", label: "7-8 years" },
  { value: "CHILD_8_9", label: "8-9 years" },
  { value: "CHILD_9_10", label: "9-10 years" },

  // Teen Suit Sizes
  { value: "TEEN_32", label: "EU 32 / UK 32 / US 32" },
  { value: "TEEN_34", label: "EU 34 / UK 34 / US 34" },

  // Adult Suit Sizes (Short, Regular, Long)
  { value: "ADULT_36S", label: "EU 36S / UK 36S / US 36S" },
  { value: "ADULT_36R", label: "EU 36R / UK 36R / US 36R" },
  { value: "ADULT_36L", label: "EU 36L / UK 36L / US 36L" },
  { value: "ADULT_38S", label: "EU 38S / UK 38S / US 38S" },
  { value: "ADULT_38R", label: "EU 38R / UK 38R / US 38R" },
  { value: "ADULT_38L", label: "EU 38L / UK 38L / US 38L" },
  { value: "ADULT_40S", label: "EU 40S / UK 40S / US 40S" },
  { value: "ADULT_40R", label: "EU 40R / UK 40R / US 40R" },
  { value: "ADULT_40L", label: "EU 40L / UK 40L / US 40L" },
  { value: "ADULT_42S", label: "EU 42S / UK 42S / US 42S" },
  { value: "ADULT_42R", label: "EU 42R / UK 42R / US 42R" },
  { value: "ADULT_42L", label: "EU 42L / UK 42L / US 42L" },
  { value: "ADULT_44S", label: "EU 44S / UK 44S / US 44S" },
  { value: "ADULT_44R", label: "EU 44R / UK 44R / US 44R" },
  { value: "ADULT_44L", label: "EU 44L / UK 44L / US 44L" },
  { value: "ADULT_46S", label: "EU 46S / UK 46S / US 46S" },
  { value: "ADULT_46R", label: "EU 46R / UK 46R / US 46R" },
  { value: "ADULT_46L", label: "EU 46L / UK 46L / US 46L" },
  { value: "ADULT_48S", label: "EU 48S / UK 48S / US 48S" },
  { value: "ADULT_48R", label: "EU 48R / UK 48R / US 48R" },
  { value: "ADULT_48L", label: "EU 48L / UK 48L / US 48L" },
  { value: "ADULT_50S", label: "EU 50S / UK 50S / US 50S" },
  { value: "ADULT_50R", label: "EU 50R / UK 50R / US 50R" },
  { value: "ADULT_50L", label: "EU 50L / UK 50L / US 50L" },
  { value: "ADULT_52S", label: "EU 52S / UK 52S / US 52S" },
  { value: "ADULT_52R", label: "EU 52R / UK 52R / US 52R" },
  { value: "ADULT_52L", label: "EU 52L / UK 52L / US 52L" },
  { value: "ADULT_54S", label: "EU 54S / UK 54S / US 54S" },
  { value: "ADULT_54R", label: "EU 54R / UK 54R / US 54R" },
  { value: "ADULT_54L", label: "EU 54L / UK 54L / US 54L" },
  { value: "ADULT_56S", label: "EU 56S / UK 56S / US 56S" },
  { value: "ADULT_56R", label: "EU 56R / UK 56R / US 56R" },
  { value: "ADULT_56L", label: "EU 56L / UK 56L / US 56L" },
  { value: "ADULT_58S", label: "EU 58S / UK 58S / US 58S" },
  { value: "ADULT_58R", label: "EU 58R / UK 58R / US 58R" },
  { value: "ADULT_58L", label: "EU 58L / UK 58L / US 58L" },
  { value: "ADULT_60S", label: "EU 60S / UK 60S / US 60S" },
  { value: "ADULT_60R", label: "EU 60R / UK 60R / US 60R" },
  { value: "ADULT_60L", label: "EU 60L / UK 60L / US 60L" }
];

const MEN_SHOE_SIZES = [
  // Infant Shoe Sizes
  { value: "INFANT_16", label: "Infant: EU 16 / UK 0.5 / US 1" },
  { value: "INFANT_17", label: "Infant: EU 17 / UK 1.5 / US 2" },
  { value: "INFANT_18", label: "Infant: EU 18 / UK 2 / US 3" },
  { value: "INFANT_19", label: "Infant: EU 19 / UK 3 / US 4" },
  { value: "INFANT_20", label: "Infant: EU 20 / UK 4 / US 5" },
  
  // Toddler Shoe Sizes
  { value: "TODDLER_21", label: "Toddler: EU 21 / UK 4.5 / US 5.5" },
  { value: "TODDLER_22", label: "Toddler: EU 22 / UK 5 / US 6" },
  { value: "TODDLER_23", label: "Toddler: EU 23 / UK 6 / US 7" },
  { value: "TODDLER_24", label: "Toddler: EU 24 / UK 7 / US 8" },
  
  // Child Shoe Sizes
  { value: "CHILD_25", label: "Child: EU 25 / UK 8 / US 8.5" },
  { value: "CHILD_26", label: "Child: EU 26 / UK 8.5 / US 9" },
  { value: "CHILD_27", label: "Child: EU 27 / UK 9.5 / US 10" },
  { value: "CHILD_28", label: "Child: EU 28 / UK 10 / US 11" },
  { value: "CHILD_29", label: "Child: EU 29 / UK 11 / US 12" },
  { value: "CHILD_30", label: "Child: EU 30 / UK 11.5 / US 12.5" },
  { value: "CHILD_31", label: "Child: EU 31 / UK 12.5 / US 13" },
  { value: "CHILD_32", label: "EU 32 / UK 13 / US 1" },
  { value: "CHILD_33", label: "EU 33 / UK 1 / US 1.5" },
  { value: "CHILD_34", label: "EU 34 / UK 2 / US 2.5" },
  
  // Teen and Adult Shoe Sizes
  { value: "TEEN_35", label: "EU 35 / UK 2.5 / US 3" },
  { value: "TEEN_36", label: "EU 36 / UK 3.5 / US 4" },
  { value: "ADULT_37", label: "EU 37 / UK 4 / US 4.5" },
  { value: "ADULT_37_5", label: "EU 37.5 / UK 4.5 / US 5" },
  { value: "ADULT_38", label: "EU 38 / UK 5 / US 5.5" },
  { value: "ADULT_38_5", label: "EU 38.5 / UK 5.5 / US 6" },
  { value: "ADULT_39", label: "EU 39 / UK 6 / US 6.5" },
  { value: "ADULT_40", label: "EU 40 / UK 6.5 / US 7" },
  { value: "ADULT_40_5", label: "EU 40.5 / UK 7 / US 7.5" },
  { value: "ADULT_41", label: "EU 41 / UK 7.5 / US 8" },
  { value: "ADULT_42", label: "EU 42 / UK 8 / US 8.5" },
  { value: "ADULT_42_5", label: "EU 42.5 / UK 8.5 / US 9" },
  { value: "ADULT_43", label: "EU 43 / UK 9 / US 9.5" },
  { value: "ADULT_44", label: "EU 44 / UK 9.5 / US 10" },
  { value: "ADULT_44_5", label: "EU 44.5 / UK 10 / US 10.5" },
  { value: "ADULT_45", label: "EU 45 / UK 10.5 / US 11" },
  { value: "ADULT_46", label: "EU 46 / UK 11 / US 11.5" },
  { value: "ADULT_46_5", label: "EU 46.5 / UK 11.5 / US 12" },
  { value: "ADULT_47", label: "EU 47 / UK 12 / US 12.5" },
  { value: "ADULT_47_5", label: "EU 47.5 / UK 12.5 / US 13" },
  { value: "ADULT_48", label: "EU 48 / UK 13 / US 13.5" },
  { value: "ADULT_48_5", label: "EU 48.5 / UK 13.5 / US 14" },
  { value: "ADULT_49", label: "EU 49 / UK 14 / US 14.5" },
  { value: "ADULT_49_5", label: "EU 49.5 / UK 14.5 / US 15" },
  { value: "ADULT_50", label: "EU 50 / UK 15 / US 15.5" }
];

const MEN_CHEST_SIZES = [
  // Infant Chest Sizes
  { value: "INFANT_38CM", label: "38 cm / 15 in" },
  { value: "INFANT_40CM", label: "40 cm / 16 in" },
  { value: "INFANT_42CM", label: "42 cm / 17 in" },
  { value: "INFANT_44CM", label: "44 cm / 17 in" },
  
  // Toddler Chest Sizes
  { value: "TODDLER_46CM", label: "46 cm / 18 in" },
  { value: "TODDLER_48CM", label: "48 cm / 19 in" },
  { value: "TODDLER_50CM", label: "50 cm / 20 in" },
  { value: "TODDLER_52CM", label: "52 cm / 21 in" },
  
  // Child Chest Sizes
  { value: "CHILD_54CM", label: "54 cm / 21 in" },
  { value: "CHILD_56CM", label: "56 cm / 22 in" },
  { value: "CHILD_58CM", label: "58 cm / 23 in" },
  { value: "CHILD_60CM", label: "60 cm / 24 in" },
  { value: "CHILD_62CM", label: "62 cm / 24 in" },
  { value: "CHILD_64CM", label: "64 cm / 25 in" },
  { value: "CHILD_66CM", label: "66 cm / 26 in" },
  { value: "CHILD_68CM", label: "68 cm / 27 in" },

  // Teen Chest Sizes
  { value: "TEEN_70CM", label: "70 cm / 28 in" },
  { value: "TEEN_72CM", label: "72 cm / 28 in" },
  { value: "TEEN_74CM", label: "74 cm / 29 in" },
  { value: "TEEN_76CM", label: "76 cm / 30 in" },
  
  // Adult Chest Sizes
  { value: "ADULT_78CM", label: "78-82 cm / 31-32 in" },
  { value: "ADULT_83CM", label: "83-87 cm / 33-34 in" },
  { value: "ADULT_88CM", label: "88-92 cm / 35-36 in" },
  { value: "ADULT_93CM", label: "93-97 cm / 37-38 in" },
  { value: "ADULT_98CM", label: "98-102 cm / 39-40 in" },
  { value: "ADULT_103CM", label: "103-107 cm / 41-42 in" },
  { value: "ADULT_108CM", label: "108-112 cm / 43-44 in" },
  { value: "ADULT_113CM", label: "113-117 cm / 45-46 in" },
  { value: "ADULT_118CM", label: "118-122 cm / 46-48 in" },
  { value: "ADULT_123CM", label: "123-127 cm / 48-50 in" },
  { value: "ADULT_128CM", label: "128-132 cm / 50-52 in" },
  { value: "ADULT_133CM", label: "133-137 cm / 52-54 in" }
];


const MEN_WAIST_SIZES = [
  // Infant Waist Sizes
  { value: "INFANT_38CM", label: "38 cm / 15 in" },
  { value: "INFANT_40CM", label: "40 cm / 16 in" },
  { value: "INFANT_42CM", label: "42 cm / 17 in" },
  { value: "INFANT_44CM", label: "44 cm / 17 in" },

  // Toddler Waist Sizes
  { value: "TODDLER_46CM", label: "46 cm / 18 in" },
  { value: "TODDLER_48CM", label: "48 cm / 19 in" },
  { value: "TODDLER_50CM", label: "50 cm / 20 in" },
  { value: "TODDLER_52CM", label: "52 cm / 21 in" },

  // Child Waist Sizes
  { value: "CHILD_54CM", label: "54 cm / 21 in" },
  { value: "CHILD_56CM", label: "56 cm / 22 in" },
  { value: "CHILD_58CM", label: "58 cm / 23 in" },
  { value: "CHILD_60CM", label: "60 cm / 24 in" },
  { value: "CHILD_62CM", label: "62 cm / 24 in" },
  { value: "CHILD_64CM", label: "64 cm / 25 in" },
  { value: "CHILD_66CM", label: "66 cm / 26 in" },
  { value: "CHILD_68CM", label: "68 cm / 27 in" },

  // Teen Waist Sizes
  { value: "TEEN_70CM", label: "70 cm / 28 in" },
  { value: "TEEN_72CM", label: "72 cm / 28 in" },
  { value: "TEEN_74CM", label: "74 cm / 29 in" },
  { value: "TEEN_76CM", label: "76 cm / 30 in" },

  // Adult Waist Sizes
  { value: "ADULT_78CM", label: "78-82 cm / 31-32 in" },
  { value: "ADULT_83CM", label: "83-87 cm / 33-34 in" },
  { value: "ADULT_88CM", label: "88-92 cm / 35-36 in" },
  { value: "ADULT_93CM", label: "93-97 cm / 37-38 in" },
  { value: "ADULT_98CM", label: "98-102 cm / 39-40 in" },
  { value: "ADULT_103CM", label: "103-107 cm / 41-42 in" },
  { value: "ADULT_108CM", label: "108-112 cm / 43-44 in" },
  { value: "ADULT_113CM", label: "113-117 cm / 45-46 in" },
  { value: "ADULT_118CM", label: "118-122 cm / 46-48 in" },
  { value: "ADULT_123CM", label: "123-127 cm / 48-50 in" },
  { value: "ADULT_128CM", label: "128-132 cm / 50-52 in" },
  { value: "ADULT_133CM", label: "133-137 cm / 52-54 in" },
  { value: "ADULT_138CM", label: "138-142 cm / 54-56 in" }
];


const CLOTHES_TYPE = {
  MEN: 'men',
  WOMEN: 'women'
}

const SIZE_TYPE = {
  DRESS: 'dress',
  SHOE: 'shoe',
  BUST: 'bust',
  HIP: 'hip',
  SUIT: 'suit',
  CHEST: 'chest',
  WAIST: 'waist',
}

const WOMEN_DRESS_SIZES_RENDER_ARRAY  = [
  { label: "Dress Size", type: SIZE_TYPE.DRESS, options: WOMEN_DRESS_SIZES, id: 'dress_size' },
  { label: "Shoe Size", type: SIZE_TYPE.SHOE, options: WOMEN_SHOE_SIZES, id: 'women_shoe_ize' },
  { label: "Bust Size", type: SIZE_TYPE.BUST, options: WOMEN_BUST_SIZES, id: 'bust_size' },
  { label: "Hip Size", type: SIZE_TYPE.HIP, options: WOMEN_HIP_SIZES, id: 'hip_size' }
]

const MENS_SIZES_RENDER_ARRAY = [
  { label: "Suit Size", type: SIZE_TYPE.SUIT, options: MEN_SUIT_SIZES, id: 'suit_size' },
  { label: "Shoe Size", type: SIZE_TYPE.SHOE, options: MEN_SHOE_SIZES, id: 'mens_shoe_size' },
  { label: "Chest Size", type: SIZE_TYPE.CHEST, options: MEN_CHEST_SIZES, id: 'chest_size' },
  { label: "Waist Size", type: SIZE_TYPE.WAIST, options: MEN_WAIST_SIZES, id: 'waist_size' }
]

export {
  USER_TYPES,
  USER_TYPE,
  USER_TYPE_TEXT,
  VIDEO_REVIEW_PERMISSIONS,
  STUDIO_LIST_PERMISSIONS,
  POSTINGPAGE_PERMISSIONS,
  MEETING_HOST,
  TINYMCE_KEY,
  WS_HOST,
  PROJECT_TYPES,
  SESSION_TIME_TYPE,
  SESSION_BOOK_TYPE,
  FREELANCER_WORK_AS,
  FREELANCER_TIMEZONE,
  SESSION_LOCATION_TYPE,
  FILE_FIELDS,
  ON_DECK_STATUS,
  ON_DECK_ORDER,
  TALENTS_UPLOAD_CSV_HEADERS,
  TALENTS_UPLOAD_CSV_SAMPLE_DATA,
  WOMEN_DRESS_SIZES,
  WOMEN_SHOE_SIZES,
  WOMEN_BUST_SIZES,
  WOMEN_HIP_SIZES,
  MEN_SUIT_SIZES,
  MEN_SHOE_SIZES,
  MEN_CHEST_SIZES,
  MEN_WAIST_SIZES,
  CLOTHES_TYPE,
  SIZE_TYPE,
  WOMEN_DRESS_SIZES_RENDER_ARRAY,
  MENS_SIZES_RENDER_ARRAY
}
