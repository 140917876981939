import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { Modal, Button } from 'react-bootstrap'
import { FaTrash, FaFilm, FaSpinner } from 'react-icons/fa';

import {
  updateStudio,
  searchUsers,
  deleteStudio,
  archiveStudio,
  unArchiveStudio,
  static_root,
  updateSession
} from '../../services'
import './style.scss'

import {
  USER_TYPE,
  USER_TYPES,
  STUDIO_LIST_PERMISSIONS,
} from '../../constants'

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { humanFileSize } from '../../utils'

import SessionCrupdateModal from './SessionCrupdateModal'
import Session from './Session'
import PostingPage from './PostingPage'
import StudioCrupdateModal from './StudioCrupdateModal'
import PostingPageCrupdateModal from './PostingPageCrupdateModal'

import {
  update as updateStudioInStore,
} from '../../store/studios'
import { useHistory } from 'react-router-dom'
import ConfirmModal from './ConfirmModal'
import { DownArrowIcon, EditIcon, SortIcon, UpArrowIcon } from '../../assets/icons/session'
import PostingLinkItem from './PostingLinkItem';
import PostingLinkModal from './PostingLinkModal';
import GroupSorter from '../PostingPage/GroupSorter';

let fnTimeoutHandler = null

export default ({ studio, refreshList, setFindFreelancerFor }) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCastingDirectorModal, setShowCastingDirectorModal] = useState(false)
  const [showCreatePostingPageModal, setShowCreatePostingPageModal] = useState(false)
  const [showCreateSessionModal, setShowCreateSessionModal] = useState(false)
  const dispatch = useDispatch()
  const [loadingSessionUsers, toggleLoadingSessionUsers] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState(null)
  const confirmCallback = useRef(null)
  const [showForm, setShowForm] = useState(false)
  const [showPostingLinkModal, setShowPostingLinkModal] = useState(false)

  const [castingDirectors, setCastingDirectors] = useState([])
  const [selectedCastingDirector, setSelectedCastingDirector] = useState(studio.casting_directors)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const searchCastingDirectors = async (email) => {
    if (fnTimeoutHandler) { clearTimeout(fnTimeoutHandler) }
    fnTimeoutHandler = setTimeout(async () => {
      toggleLoadingSessionUsers(true)
      const users = await searchUsers(email, USER_TYPES.CASTING_DIRECTOR)
      setCastingDirectors(users)
      toggleLoadingSessionUsers(false)
    }, 1000)
  }

  const handleStudioArchive = (studio) => {
    const callback = async () => {
      await archiveStudio(studio._id)
      refreshList()
    }
    setConfirmMessage(`Want to archive ${studio.name}?`)
    confirmCallback.current = callback
  }

  const deleteStudioHandle = async (studio) => {
    const callback = async () => {
      await deleteStudio(studio._id)
      refreshList()
    }
    setConfirmMessage(`Want to delete ${studio.name}?`)
    confirmCallback.current = callback
  }

  const handleStudioUnArchive = async (studio) => {
    await unArchiveStudio(studio._id)
    refreshList()
  }

  const confirmCancel = () => {
    confirmCallback.current = null
    setConfirmMessage('')
  }

  const confirmYes = () => {
    if (confirmCallback.current) {
      confirmCallback.current()
    }
    confirmCancel()
  }
  const onOKAssignCastingDirector = async () => {
    const formData = new FormData()
    formData.append('casting_directors', selectedCastingDirector.map(it => it._id))
    const res = await updateStudio(formData, studio._id)
    dispatch(updateStudioInStore(res))
    setShowCastingDirectorModal(false)
  }

  // Define castingLogo here
  const castingLogo = studio.casting_directors.map(cd => cd.logo)[0];

  const updateSessionOrder = async (orderedSession) => {
    try{
      setLoading(true)
      const promises = orderedSession.map(async(session, index) => {
        const formData = new FormData()
        formData.append('sort_order', index)
        return await updateSession(session._id, formData)
      })
      const sessions  = await Promise.all(promises)
      if (studio.sessions) {
        const temp = { ...studio, sessions };
        dispatch(updateStudioInStore(temp));
      }
    }catch(error){
      console.log('error:>', error)
    }finally{
      setLoading(false)
     
    }
  }
  return (
    <>
      <div className="project-item" key={studio._id}>
        <div className={`loading ${loading ? 'show' : ''}`}>
          <div className='d-flex flex-column align-items-center text-danger bg-white px-4 pt-3 pb-1 border-danger border'>
              <FaSpinner size='32px' className='spinning' />
            <span className='h4'>Processing...</span>
            <span className="uploading-percent-indicator" />
          </div>
        </div>
        {studio?.limelite_project_id && <img
            src={require("../../assets/lime-lite.png")}
            className="lime-lite-indicator"
            alt="logo"
        /> }
        <div className='d-flex flex-column w-100'>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="studio-logo mr-2">
                {castingLogo ? (
                  <img
                    className="logo d-block mx-auto"
                    src={static_root + castingLogo}
                    alt={studio.name}
                    style={{ width: '50px', height: '50px' }}
                  />
                ) : (
                  <FaFilm size={20} />
                )}
              </div>
              <h4 className="h-20-600 mb-0 mr-3">{studio.name}</h4>
              <label className="file-size mr-3 mb-0">{humanFileSize(studio.size)}</label>
              {STUDIO_LIST_PERMISSIONS.CAN_CREATE_STUDIO() && (
                <div className="action-wrap">
                  <button
                    title="Edit Project"
                    className="name-edit-button"
                    onClick={() => { setShowEditModal(true) }} >
                    <EditIcon />
                  </button>
              </div>)}
            </div>
            <div className="cursor-pointer expand-button-container" onClick={() => setShowForm(!showForm)}>
              {showForm ? <UpArrowIcon /> : <DownArrowIcon />}
            </div>
          </div>
          {!showForm && <span className='h-12-400 ls-2 text-secondary mt-2'>{studio.sessions.length} Sessions</span>}
        </div>
        {showForm ? 
          <div className=''>
            <div className="d-flex flex-column mt-3">
              <div className="list-container mb-3">
                <div className='d-flex align-items-center justify-content-between'>
                  <span className="h-12-600 ls-16 text-muted">SESSIONS</span>
                  <GroupSorter
                    groups={studio.sessions}
                    update={updateSessionOrder}
                    title="Sort Sessions"
                    btnContent={ [<SortIcon />, "Sort Sessions"]}
                    btnClass='action-button h-10-600 ml-2'
                  />

                </div>
                {studio.sessions.map((session, index) => (
                  <React.Fragment key={session._id}>
                    <Session
                      session={session}
                      studio={studio}
                      findFreelancer={setFindFreelancerFor}
                    />
                    {index !== studio.sessions.length - 1 && <hr className="w-100 mt-2 mb-0" />}
                  </React.Fragment>
                ))}
              </div>
              {studio.postingPages.length > 0 && <div className="list-container mb-3">
                <span className="h-12-600 ls-16 text-muted">POSTING PAGES</span>
                {studio.postingPages.map((pp, index) => (
                  <React.Fragment key={index}>
                    <PostingPage
                      studio={studio}
                      postingPage={pp}
                    />
                    {index !== studio.postingPages.length - 1 && <hr className="w-100 mt-2 mb-0" />}
                  </React.Fragment>
                ))}
              </div>}
              {studio.posting_links.length > 0 && <div className="list-container mb-3">
                <span className="h-12-600 ls-16 text-muted">POSTING LINKS</span>
                {studio.posting_links.map((pp, index) => (
                  <React.Fragment key={index}>
                    <PostingLinkItem studio={studio} postingLink={pp}/>
                    {index !== studio.posting_links.length - 1 && <hr className="w-100 mt-2 mb-0" />}
                  </React.Fragment>
                ))}
                
              </div>}
            </div>
            <div className='bottom-btn-container d-flex'>
              <button
                className='action-button h-14-600'
                onClick={() => {
                  history.push(`/session/${studio.uri}/edit/new`)
                }}>+ Add Session
              </button>
              <button
                className='action-button h-14-600'
                onClick={() => { setShowCreatePostingPageModal(true) }}>
                + Add Posting Page
              </button>
              <button
                className='action-button h-14-600'
                onClick={() => { setShowPostingLinkModal(true) }}>
                + Add Posting Link
              </button>
              {STUDIO_LIST_PERMISSIONS.CAN_ARCHIVE_STUDIO() && (
                <button
                  className='danger-button h-14-600'
                  onClick={() => {
                    if (!studio.is_archived) {
                      handleStudioArchive(studio)
                    } else {
                      handleStudioUnArchive(studio)
                    }
                  }}>
                  {!!studio.is_archived ? "Restore" : "Archive"}
                </button>
              )}
              {USER_TYPE.IS_SUPER_ADMIN() && (
                <button
                  title='Delete Project'
                  className='danger-button h-14-600'
                  onClick={() => deleteStudioHandle(studio)}><FaTrash />
                </button>
              )}
            </div>
          </div> : null}
      </div>
      <StudioCrupdateModal
        studio={studio}
        show={showEditModal}
        onHide={() => { setShowEditModal(false) }}
      />
       <ConfirmModal 
        confirmMessage={confirmMessage}
        confirmCancel={confirmCancel}
        confirmYes={confirmYes} />
      <SessionCrupdateModal
        show={showCreateSessionModal}
        studio={studio}
        onHide={() => { setShowCreateSessionModal(false) }}
      />
      <PostingPageCrupdateModal
        show={showCreatePostingPageModal}
        studio={studio}
        onHide={() => { setShowCreatePostingPageModal(false) }}
      />
      <Modal
        className="session-modal"
        show={showCastingDirectorModal}
        onHide={() => { setShowCastingDirectorModal(false) }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a Casting Director</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AsyncTypeahead
            id="async-user-search"
            isLoading={loadingSessionUsers}
            minLength={3}
            defaultSelected={selectedCastingDirector}
            labelKey={option => `${option.first_name} ${option.last_name}`}
            onSearch={searchCastingDirectors}
            options={castingDirectors}
            placeholder="Search by email..."
            renderMenuItemChildren={(option, props) => (
              <div>
                {`${option.first_name} ${option.last_name}`}
              </div>
            )}
            onChange={(selected) => {
              setSelectedCastingDirector(selected)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCastingDirectorModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={onOKAssignCastingDirector}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <PostingLinkModal
        show={showPostingLinkModal}
        studio={studio}
        onHide={() => setShowPostingLinkModal(false)}
      />
    </>
  )
}
