import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';

import { getUser, getUserById, uploadImage, updateUserFields, uploadFile } from '../../services'
import { dataURLtoFile } from '../../utils'
import { USER_TYPES } from '../../constants'
import AboutMe from './components/AboutMe'
import AdditionalDetails from './components/AdditionalDetails'
import MySchedule from './components/MySchedule'
import Account from './components/Account'
import { BackToIcon, CloseIconLg, RightArrow } from '../../assets/icons/session'
import './style.scss'

const TalentPage = ({ duringOnboard = false }) => {
  const [user, setUser] = useState(null)
  const [email, setEmail] = useState('')
  const [agent, setAgent] = useState('')
  const [optIn, setOptIn] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [avatar64, setAvatar64] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [cameraError, setCameraError] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [formError, setFormError] = useState('')
  const [photos, setPhotos] = useState([])
  const [agentEmail, setAgentEmail] = useState('')
  const [agentPhone, setAgentPhone] = useState('')
  const [instagramHandle, setInstagramHandle] = useState('')
  const [tiktokHandle, setTikTokHandle] = useState('')
  const [ft, setFt] = useState('')
  const [inches, setInches] = useState('')
  const webcamRef = useRef(null)
  const [saving, setSaving] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState('')
  const pUrl = window.localStorage.getItem('prev_url')
  const [onboardUrl, setOnboardUrl] = useState('')
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('');
  const buttonRef = useRef(null);
  const [clothesType, setClothesType] = useState()
  const [sizeObject, setSizeObject] = useState(null);
  
  useEffect(() => {
    setOnboardUrl(window.sessionStorage.getItem('onboard_url'))
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  useEffect(() => {
    if (buttonRef.current && activeTab === 'schedule') {
      buttonRef.current.click();
    }
  }, [activeTab]);

  const loadData = () => {
    const u = getUser()
    if (u) {
      getUserById(u.id).then(data => {
        if (data.user_type === USER_TYPES.TALENT) {
          if (pUrl && data.first_name && data.last_name && data.logo && data.phone) {
            window.localStorage.removeItem('prev_url')
            window.location.href = pUrl
          }
          setUser(data)
          setFirstName(data.first_name || '')
          setLastName(data.last_name || '')
          setAgent(data.agent || '')
          setPhoneNumber(data.phone || '')
          setEmail(data.email)
          setOptIn(data.opt_in)
          setPhotos(data.photos || [])
          setAgentEmail(data.agent_email || '')
          setAgentPhone(data.agent_phone || '')
          setInstagramHandle(data.instagram_handle || '')
          setTikTokHandle(data.tiktok_handle || '')
          setDateOfBirth(data?.date_of_birth ? new Date(data.date_of_birth) : '')
          if (data.height) {
            const height = JSON.parse(data.height);
            setFt(height.ft || '')
            setInches(height.inches || '')
          }
          if(data.clothes_type){
            setClothesType(data.clothes_type)
          }
          if(data.clothes_size_object){
            const clothes_size_object = JSON.parse(data.clothes_size_object);
            setSizeObject(clothes_size_object)
          }
          if (data.logo) {
            setAvatar64(data.logo)
          }
        }
      })
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const isValidPhoneNumber = (phNumber) => {
    const phoneNumberRegex = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
    return phoneNumberRegex.test(phNumber);
  }

  const validate = () => {

    if (!firstName) {
      setFormError('First name required')
      return false
    }
    if (!lastName) {
      setFormError('Last name required')
      return false
    }
    if(!phoneNumber || phoneNumber === "undefined"){
      setFormError('Phone number required')
      return false
    }
    if (!isValidPhoneNumber(phoneNumber)) {
      setFormError('Please Enter a valid Phone number')
      return false
    }
    if (!email) {
      setFormError('Email required')
      return false
    }
    if (photos.length === 0) {
      setFormError('Please upload a Headshot')
      return false
    }
    if (agentEmail && !validateEmail(agentEmail)) {
      setFormError('Invalid email')
      return false
    }
    if (agentPhone && !isValidPhoneNumber(agentPhone)) {
      setFormError('Please Enter a valid Agent Phone number')
      return false
    }
    if(!validateDateOfBirth()){
      setFormError('Date of birth cannot be in the future');
      return false;
    }

    if (passwordConfirm !== password) {
      setFormError('Confirm password does not match')
      return false
    }
    if(password && !oldPassword){
      setFormError('Old Password is required')
      return false
    }
    setFormError('')
    return true
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validateDateOfBirth = () => {
    if (!dateOfBirth) { return true }
    const currentDate = new Date();
    const selectedDate = new Date(dateOfBirth);
    if (selectedDate > currentDate) {
      return false;
    }
    
    setFormError('');
    return true;
  }

  const setAvatarImg = async (file) => {
    const stime = + new Date()
    setUploading(true)
    const res = await uploadImage(file)
    setAvatar64(res.name)
    const duration = +new Date() - stime
    setTimeout(() => {
      setUploading(false)
      // hack around waiting content download time.
      // assume download takes same amount of time as upload
    }, duration)
  }

  const takePhoto = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot()
      const file = dataURLtoFile(imageSrc, `${new Date()}.jpg`)
      setAvatarImg(file)
    },
    [webcamRef]
  )

  const updateUser = async () => {
    try{
      setFormError('')
      const v = validate()
      if (v) {
        setSaving(true)
        const fields = {
          email,
          agent,
          first_name: firstName,
          last_name: lastName,
          phone: phoneNumber,
          oldPassword,
          password,
          logo: photos[0],
          agent_email: agentEmail,
          agent_phone: agentPhone,
          instagram_handle: instagramHandle,
          tiktok_handle: tiktokHandle,
          date_of_birth: dateOfBirth || '',
          clothes_type: clothesType
        }
        const formData = new FormData()
        Object.keys(fields).forEach(key => {
          formData.append(key, fields[key])
        })
        photos.forEach(p => {
          formData.append('photos', p)
        })
  
        const height = {ft, inches}
        formData.append('height', JSON.stringify(height))
        formData.append('clothes_size_object', JSON.stringify(sizeObject))
  
        const res = await updateUserFields(user._id, formData)
        if(res.error){
          setFormError(res.error)
        }
        setSaving(false)
        loadData()
        if(pUrl){
          window.localStorage.removeItem('prev_url')
          window.location.href = pUrl
        }
      }
    } catch(e){
      setSaving(false)
    }
    
  }

  const uploadPhoto = async (files) => {
    setUploading(true)
    const addedPhotos = []
    for (let i = 0; i < Math.min(4 - photos.length, files.length); i++) {
      const res = await uploadFile(files[i])
      addedPhotos.push(res.key)
    }
    setPhotos(photos.concat(addedPhotos))
    setUploading(false)
    document.querySelector('#photo').value = []
  }

  useEffect(() => {
    const tabs = document.querySelectorAll('.nav-link');
    const tabContents = document.querySelectorAll('.tab-pane');

    tabs.forEach(tab => {
      tab.addEventListener('click', () => {
        const target = tab.getAttribute('data-target');

        tabs.forEach(t => {
          if (t.getAttribute('data-target') === target) {
            t.classList.add('active');
          } else {
            t.classList.remove('active');
          }
        });

        tabContents.forEach(content => {
          if (content.getAttribute('id') === target) {
            content.classList.add('show', 'active');
          } else {
            content.classList.remove('show', 'active');
          }
        });
      });
    });
  }, []);

  return (
    <div className="talent-page">
      {onboardUrl && <div className='back-to-session'>
        <a href={onboardUrl} className='d-flex align-items-center'>
          <BackToIcon />
          <span className='ml-2 h-14-600 ls-2'>Go back to session</span>
        </a>
        <button onClick={()=> {
          setOnboardUrl('')
          window.sessionStorage.removeItem('onboard_url')}}>
          <CloseIconLg />
        </button>  
      </div>}
      <div className='talent-page-content'>
        <h3 className="mb-4 mb-md-3">
          {duringOnboard ? "Complete your profile and continue": "Profile Information"}
        </h3>

        <div className='d-flex flex-column flex-md-row gap-2'>
          <ul className="nav nav-tabs account-tabs" role="tablist">
            <li className="nav-item mb-2" role="presentation">
              <button className="nav-link active" data-target="about_me">
                About Me
                <span className="badge"><RightArrow /></span>
              </button>
            </li>
            <li className="nav-item mb-2" role="presentation">
              <button className="nav-link" data-target="additional_details">
                Additional Details
                <span className="badge"><RightArrow /></span>
              </button>
            </li>
            <li className="nav-item mb-2" role="presentation">
              <button className="nav-link" data-target="schedule" ref={buttonRef}>
                My Schedule
                <span className="badge"><RightArrow /></span>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" data-target="account">
                Account
                <span className="badge"><RightArrow /></span>
              </button>
            </li>
          </ul>
          <div className="tab-content tab-container">
            <div className="tab-pane show active" id="about_me">
              <AboutMe
                email={email} 
                photos={photos}
                setPhotos={setPhotos}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                uploadPhoto={uploadPhoto}
                updateUser={updateUser}
                uploading={uploading}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                saving={saving}
                formError={formError}
                dateOfBirth={dateOfBirth}
                duringOnboard={duringOnboard}
                setDateOfBirth={setDateOfBirth}  />
            </div>
            <div className="tab-pane" id="additional_details">
              <AdditionalDetails 
                setAgent={setAgent}
                agent={agent}
                agentEmail={agentEmail}
                setAgentEmail={setAgentEmail}
                agentPhone={agentPhone}
                setAgentPhone={setAgentPhone}
                instagramHandle={instagramHandle}
                setInstagramHandle={setInstagramHandle}
                tiktokHandle={tiktokHandle}
                setTikTokHandle={setTikTokHandle}
                ft={ft}
                setFt={setFt}
                inches={inches}
                setInches={setInches}
                updateUser={updateUser}
                saving={saving}
                formError={formError}
                clothesType={clothesType}
                setClothesType={setClothesType}
                sizeObject={sizeObject}
                setSizeObject={setSizeObject} />
              </div>
            <div className="tab-pane" id="schedule"><MySchedule /></div>
            <div className="tab-pane" id="account">
              <Account 
                oldPassword={oldPassword}
                setOldPassword={setOldPassword}
                password={password}
                setPassword={setPassword}
                passwordConfirm={passwordConfirm}
                setPasswordConfirm={setPasswordConfirm}
                updateUser={updateUser}
                saving={saving} 
                formError={formError} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TalentPage
