import React, { useEffect } from "react";
import "./style.scss";
import { InstaLogo, TikTokLogo } from "../../../assets/icons/session";
import SelectSize from "../../../components/size-select";

const AdditionalDetails = ({
  setAgent,
  agent,
  agentEmail,
  setAgentEmail,
  agentPhone,
  setAgentPhone,
  instagramHandle,
  setInstagramHandle,
  tiktokHandle,
  setTikTokHandle,
  ft,
  setFt,
  inches,
  setInches,
  updateUser,
  saving,
  formError,
  clothesType,
  setClothesType,
  sizeObject,
  setSizeObject
}) => {

  const onSelectSize = (e, type) => {
    const sizeObjectCopy = {...sizeObject}
    sizeObjectCopy[type] = { value: e.value, label: e.label }
    setSizeObject(sizeObjectCopy)
  }

  return (
    <div className="AdditionalDetails white-bg">
      <div>
        <div className="input-row align-items-center justify-content-between">
          <label>Height</label>
          <div className="d-flex align-items-center">
            <div className="div-input">
              <input 
                type="text" 
                name="ft" 
                id="ft" 
                className="height-input" 
                value={ft}
                onChange={(ev) => setFt(ev.target.value)}/>
              <div className="h-12-600 indicator">ft</div>
            </div>
            <div className="div-input ml-2">
              <input
                type="text"
                name="inches"
                id="inches"
                className="height-input"
                value={inches}
                onChange={(ev) => setInches(ev.target.value)}/>
              <div className="h-12-600 indicator">in</div>
            </div>
          </div>
        </div>
        <SelectSize 
          sizeObject={sizeObject}
          setSizeObject={setSizeObject}
          onSelectSize={onSelectSize}
          clothesType={clothesType}
          setClothesType= {setClothesType}
        />
      </div>
      <div className="horizontal-divider" />
      <div>
        <h3 className="h-16-600">Socials</h3>
        <div className="input-row mt-3">
          <div className="input-item">
            <div className="f-flex align-items-center">
              <InstaLogo />
              <label htmlFor="instagram" className="ml-2">
                Instagram
              </label>
            </div>
            <div className="pl-3">
              <input
                value={instagramHandle}
                onChange={(ev) => setInstagramHandle(ev.target.value)}
                type="text"
                name="instagram"
                id="instagram"
                className="input"
              />
            </div>
          </div>
          <div className="input-item">
            <div className="f-flex align-items-center">
              <TikTokLogo />
              <label htmlFor="tiktok" className="ml-2">
                Tiktok
              </label>
            </div>
            <div className="pl-3">
              <input
                value={tiktokHandle}
                onChange={(ev) => setTikTokHandle(ev.target.value)}
                type="text"
                name="tiktok"
                id="tiktok"
                className="input"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="horizontal-divider" />
      <div className="d-flex flex-column">
        <h3 className="h-16-600">Agent Information</h3>
        <div className="input-row mt-3">
          <div className="input-item">
            <label htmlFor="agent_name">Name</label>
            <input
              value={agent}
              onChange={(ev) => setAgent(ev.target.value)}
              type="text"
              name="agent_name"
              id="agent_name"
              className="input"
              required
            />
          </div>
        </div>
        <div className="input-row mt-3">
          <div className="input-item">
            <label htmlFor="phone">Phone</label>
            <input
              value={agentPhone}
              onChange={(ev) => setAgentPhone(ev.target.value)}
              type="text"
              name="phone"
              id="phone"
              className="input"
              required
            />
          </div>
          <div className="input-item">
            <label htmlFor="email">Email</label>
            <input
              value={agentEmail}
              onChange={(ev) => setAgentEmail(ev.target.value)}
              type="email"
              name="email"
              id="email"
              className="input"
            />
          </div>
        </div>
        <div className="d-flex align-items-center mt-4">
          {formError && <div className="error-indicator">{formError}</div>}
          <button
            type="submit"
            className="primary-solid-button h-14-700 ml-auto"
            onClick={updateUser}
          >
            {saving ? 'Saving' :'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdditionalDetails;
