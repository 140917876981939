import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Image, Modal } from "react-bootstrap";
import UserForm from "./userForm";

import {
  FaInfoCircle,
  FaUser,
} from "react-icons/fa";
import { USER_TYPES, VERSION } from "../constants";
import { getUser, getUserById, static_root } from "../services";
import defaultLogo from "../assets/heyjoe-white.png";

const UserDropDown = (props) => {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [logoFailed, setLogoFailed] = useState(false);
  const [needCredentials, setNeedCredentials] = useState(false);
  const [editUser, setEditUser] = useState(false);

  useEffect(() => {
    const u = getUser();
    setUser(u);
    if (!u) return;
    getUserById(u.id).then((data) => {
      if (!data) return;
      const need =
        [USER_TYPES.SUPER_ADMIN, USER_TYPES.CASTING_DIRECTOR].includes(
          data.user_type
        ) &&
        (!data.first_name || !data.last_name);
      setNeedCredentials(need);
    });
  }, [editUser]);

  if(!user) return null;

  const closeUserEdit = () => {
    setEditUser(false);
  };

  const showCredentials = !![
    USER_TYPES.SUPER_ADMIN,
    USER_TYPES.CASTING_DIRECTOR,
  ].includes(user.user_type);

  const logo = props?.logo || user?.logo || defaultLogo;

  return (
    <div className="user-dropdown">
      <Dropdown className="ml-0 header-user-menu" alignRight>
        <Dropdown.Toggle variant="danger" id="dropdown-basic" className="rounded-0">
          <span className="ml-2 h5">
            {user.user_type === USER_TYPES.CASTING_DIRECTOR || logoFailed ? (
              <FaUser />
            ) : (
              <Image
                height={40}
                width={40}
                src={static_root + logo}
                roundedCircle={true}
                onError={(e) => {
                  setLogoFailed(true);
                }}
                style={{ objectFit: "cover" }}
              />
            )}
          </span>
          {needCredentials && (
            <FaInfoCircle
              color="white"
              size="22"
              className="mt-n1 ml-2 fade-alert"
              title="Credentials are required"
            />
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item className="text-secondary">
            {user.email}
            <br />
            <div className="d-flex">
              {user.user_type}
              <small className="ml-auto">&nbsp;&nbsp;{VERSION}</small>
            </div>
          </Dropdown.Item>
          {user.user_type === USER_TYPES.SUPER_ADMIN && (
            <Dropdown.Item
              onClick={() => {
                window.open("/heyjoe-admin");
              }}
            >
              Admin
            </Dropdown.Item>
          )}
          {[USER_TYPES.SESSION_MANAGER, USER_TYPES.SUPER_ADMIN].includes(
            user.user_type
          ) && (
            <Dropdown.Item
              onClick={() => {
                window.open("/freelancer-profile");
              }}
            >
              Freelancer Profile
            </Dropdown.Item>
          )}
          {showCredentials && (
            <Dropdown.Item
              onClick={() => {
                setEditUser(!editUser);
              }}
            >
              Credentials
              {needCredentials && (
                <FaInfoCircle
                  color="red"
                  size="18"
                  className="mt-n1 ml-2 fade-alert"
                  title="Credentials are required"
                />
              )}
            </Dropdown.Item>
          )}
          {!showCredentials && (
            <Dropdown.Item onClick={() => history.push("/")}>
              Profile
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => {
              window.localStorage.removeItem("token");
              window.location.reload(true);
            }}
            className="d-flex"
          >
            Logout
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              window.open("/delete-account", "_self");
            }}
            className="d-flex text-danger"
          >
            Delete Account
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={!!editUser} onHide={closeUserEdit}>
        <Modal.Header closeButton>
          <h5 className="mb-0">
            Edit User {showCredentials ? "Credentials / " : ""} Logo
          </h5>
        </Modal.Header>
        <Modal.Body>
          <UserForm key={editUser} onClose={closeUserEdit} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserDropDown;
